import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

// components
import Login from '@/views/no_auth/Login.vue';
import Dashboard from '@/views/auth/Dashboard.vue';
import Error403 from '@/components/errors/Error403.vue';
import Sales from '@/views/auth/Sales.vue';
import SubscriberDetail from '@/views/auth/SubscriberDetail.vue';
import ProductsLite from '@/views/auth/ProductsLite.vue';
import ProductDetail from '@/views/auth/ProductDetail.vue';
import Subscribers from '@/views/auth/Subscribers.vue';
import ReceivedOrders from '@/views/auth/ReceivedOrders.vue';
import ReceivedOrderDetail from '@/views/auth/ReceivedOrderDetail.vue';
import BillsOfDelivery from '@/views/auth/BillsOfDelivery.vue';
import BillsOfDeliveryDetail from '@/views/auth/BillsOfDeliveryDetail.vue';
import SaleDetail from '@/views/auth/SaleDetail.vue';

// utils
import isAuth from '@/utils/isAuth';
import store from '@/store/store';
import RepositoryFactory from '@/api/RepositoryFactory';

const ReceivedOrderRepository = RepositoryFactory.get('receivedorder');
const ProductRepository = RepositoryFactory.get('product');

import { IUser, IRole } from '@/models/user.models';
import { AxiosResponse } from 'axios';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '',
        component: () => import('@/NoAuth.vue'),
        meta: {
            auth: false,
        },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: {
                    title: 'Přihlášení',
                },
                component: Login,
            },
        ],
    },
    {
        path: '',
        component: () => import('@/Auth.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/cleardata',
                name: 'dataclear',
                meta: {
                    title: 'Clear Data',
                },
                beforeEnter: async () => {
                    store.dispatch('clearData');
                    location.href = '/';
                },
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                meta: {
                    title: 'Přehled',
                },
                component: Dashboard,
            },
            {
                path: '/trade/promo',
                name: 'sales',
                meta: {
                    title: 'Letákové akce',
                },
                component: Sales,
            },
            {
                path: '/trade/promo/:id',
                name: 'saledetail',
                meta: {
                    title: 'Detail letákové akce',
                },
                component: SaleDetail,
            },
            {
                path: '/subscribers/:id',
                name: 'subscriberdetail',
                meta: {
                    title: 'Detail odběratele',
                },
                component: SubscriberDetail, // TODO: Add detail
            },
            {
                path: '/products',
                name: 'productslite',
                meta: {
                    title: 'Sortiment - Položky',
                },
                component: ProductsLite,
            },
            {
                path: '/products/:id/:abra?',
                name: 'productdetail',
                meta: {
                    title: 'Detail produktu',
                },
                beforeEnter: async (to, from: Route, next: NavigationGuardNext<Vue>) => {
                    if (to.params.abra) {
                        try {
                            const res: AxiosResponse<{
                                id: number;
                            }> = await ProductRepository.getProductByAbraId(to.params.id);
                            const data = res.data;
                            next({ name: 'productdetail', params: { id: String(data) } });
                        } catch (error) {}
                    } else {
                        next();
                    }
                },
                component: ProductDetail,
            },
            {
                path: '/subscribers',
                name: 'subscribers',
                meta: {
                    title: 'Odběratelé',
                },
                component: Subscribers,
            },
            {
                path: '/receivedorders',
                name: 'receivedorders',
                meta: {
                    title: 'Objednávky přijaté',
                },
                component: ReceivedOrders,
            },
            {
                path: '/receivedorders/:id',
                name: 'receivedorderdetail',
                meta: {
                    title: 'Detail objednávky přijaté',
                },
                component: ReceivedOrderDetail,
            },
            {
                path: '/billsofdelivery',
                name: 'billsofdelivery',
                meta: {
                    title: 'Dodací listy',
                },
                component: BillsOfDelivery,
            },
            {
                path: '/billsofdelivery/:id/:bod?',
                name: 'billsofdeliverydetail',
                meta: {
                    title: 'Detail dodacího listu',
                },
                component: BillsOfDeliveryDetail,
                beforeEnter: async (to, from: Route, next: NavigationGuardNext<Vue>) => {
                    if (to.params.bod) {
                        try {
                            const res: AxiosResponse<{
                                id: number;
                            }> = await ReceivedOrderRepository.getBillOfDeliveryId(to.params.id);
                            const data = res.data;
                            next({ name: 'billsofdeliverydetail', params: { id: String(data.id) } });
                        } catch (error) {}
                    } else {
                        next();
                    }
                },
            },
        ],
    },
    {
        path: '',
        component: () => import('@/views/FullPage.vue'),
        children: [
            {
                path: '/error/403',
                name: 'error-403',
                component: Error403,
            },
        ],
    },
    {
        path: '/*',
        beforeEnter: async (to, from, next) => {
            console.log('Hitting this hard!');
            if (isAuth()) {
                next({ path: '/dashboard' });
            } else {
                next({ path: '/login' });
            }
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

const hasPermission = (routeRoles: string[] | undefined, userRoles: IRole[] | undefined): boolean => {
    if (typeof routeRoles === 'undefined' || !routeRoles.length) return true;
    if (typeof userRoles === 'undefined' || !userRoles.length) return false;
    for (let i = 0; i < routeRoles.length; i++) {
        const routeRole: string = routeRoles[i];
        if (userRoles.find((userRole: IRole) => userRole.name === routeRole)) {
            return true;
        }
    }
    return false;
};

const neededCRMRole = 'USER_CRM';
const neededSCMRole = 'USER_SCM';
const hasCRMPermission = (userRoles: IRole[] | undefined): boolean => {
    if (typeof userRoles === 'undefined' || !userRoles.length) return false;
    return !!userRoles.find((x: IRole) => neededCRMRole == x.name);
};
const hasSCMPermission = (userRoles: IRole[] | undefined): boolean => {
    if (typeof userRoles === 'undefined' || !userRoles.length) return false;
    return !!userRoles.find((x: IRole) => neededSCMRole == x.name);
};

router.beforeEach(async (to, from, next) => {
    document.title = `CRM | ${to?.meta?.title}`;
    if (to.path === '/cleardata') {
        if (to.query.path) {
            next({ path: to.query.path as string });
            return;
        }
        next();
        return;
    }
    if (to.path === '/') {
        if (isAuth()) {
            next({ path: '/dashboard' });
        } else {
            next({ path: '/login' });
        }
    }
    if (to.matched.some((record) => record.meta.auth === true)) {
        if (!isAuth()) {
            next({
                path: '/login',
                query: { path: to.path },
                replace: true,
            });
        } else {
            const userData: IUser = await store.dispatch('getUserData');
            const routeRoles: string[] | undefined = to?.meta?.roles;
            if (!hasCRMPermission(userData?.roles)) {
                if (hasSCMPermission(userData?.roles)) {
                    location.href = process.env.VUE_APP_SCM_URL + '/cleardata';
                    return;
                } else {
                    router.push({ name: 'error-403' });
                }
            } else if (hasPermission(routeRoles, userData?.roles)) {
                next();
            } else {
                router.push({ name: 'error-403' });
            }
        }
    } else if (to.matched.some((record) => record.meta.auth === false)) {
        if (isAuth()) {
            next({
                path: '/dashboard',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
