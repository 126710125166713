
import { Component, Vue, Mixins } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { formatDate, formatDateTime } from '@/utils/dateUtils';
import { ErrorController } from '@/utils/error.controller';
import { IProduct, IProductStockTimeline } from '@/models/product.models';
import { IStoreCard } from '@/models/storecard.models';
import StockTimelineChart from '@/components/product/StockTimeline.vue';
import { ChartDataSets } from 'chart.js';
import { ISubscriber, ISubscriberWarehouse } from '@/models/subscriber.models';
import NewSubscriberModal from '@/components/subscriber/NewSubscriber.vue';
import NewWarehouseModal from '@/components/subscriber/NewSubscriberWarehouse.vue';
import { SilModalsMixin } from '@/mixins/modals';
import { IContact, IContactAddress } from '@/models/contact.models';
import { IReceivedOrder, IReceivedOrderItem } from '@/models/receivedorder.models';

const billsOfDelivery = repository.get('billsofdelivery');
const receivedOrderRepo = repository.get('receivedorder');

@Component({})
export default class ReceivedOrderDetail extends Mixins(SilModalsMixin) {
    private receivedOrder: IReceivedOrder | null = null;
    private isLoading = false;

    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    items?: IReceivedOrderItem[] = [];
    orderMap: any = {};
    filter = '';
    fields: any[] = [
        {
            key: 'storecardCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'storecardName',
            sortable: true,
            label: 'Název',
        },
        {
            key: 'quantity',
            sortable: true,
            label: 'KS',
        },
        {
            key: 'quantityCartons',
            sortable: true,
            label: 'KRT',
        },
        {
            key: 'quantityPalletes',
            sortable: true,
            label: 'PAL',
            formatter: (value: number) => {
                if (value != null) return Math.round(value * 100) / 100;
                return undefined;
            },
        },
    ];

    private goBack() {
        const routeName: string = this.$route.params.from ?? 'receivedorders';
        this.$router.push({ name: routeName });
    }

    private formatDate(date: string) {
        return date ? formatDate(date) : '';
    }

    private formatDateTime(date: string) {
        return date ? formatDateTime(date) : '';
    }

    private async getReceivedOrder() {
        try {
            const idReceivedOrder: string = this.$route.params.id;
            const res: AxiosResponse<IReceivedOrder> = await receivedOrderRepo.get(idReceivedOrder);
            const data = res.data;
            this.receivedOrder = data;
            this.items = data.items;
        } catch (_) {}
    }

    private get deliveryDates() {
        if (this.receivedOrder?.items?.length) {
            return formatDate(this.receivedOrder.items[0].deliveryDate);
        }
        return '';
    }

    private get getWarehouseTPLCode() {
        if (this.receivedOrder != null && this.receivedOrder.contact?.warehouses?.length) {
            return this.receivedOrder.contact.warehouses[0].tplCode;
        }
        return '';
    }

    private get getWarehouseInfo() {
        if (this.receivedOrder != null && this.receivedOrder.contact?.warehouses?.length) {
            const warehouse = this.receivedOrder.contact.warehouses[0];
            return `${warehouse.name} (TPL ID: ${warehouse.tplCode})`;
        }
        return '';
    }

    async created() {
        this.getReceivedOrder();
    }

    private get contactName() {
        const item = this.receivedOrder;
        if (!item) return '';
        if (!item.contact?.warehouses?.length) return item.contactName;
        const warehouse = item.contact.warehouses[0];
        if (!warehouse?.subscriber) return item.contactName;
        return `${warehouse.subscriber.name}`;
    }
}
