import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import { IBillOfDelivery } from '@/models/billsofdelivery.models';
import moment from 'moment';

const resource = 'crm/billofdelivery';

export default class BillsOfDeliveryRepository {
    public static getAll = async (
        start: moment.Moment,
        end: moment.Moment
    ): Promise<AxiosResponse<IBillOfDelivery[]>> => {
        try {
            return Repository.get(`${resource}`, {
                params: {
                    start: start.format('YYYY-MM-DD'),
                    end: end.format('YYYY-MM-DD'),
                },
            });
        } catch (error) {
            throw error;
        }
    };
    public static get = async (id: string): Promise<AxiosResponse<IBillOfDelivery>> => {
        try {
            return Repository.get(`${resource}/${id}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    public static saveItemExtras = async (id: number, line: number, extras: any): Promise<any> => {
        try {
            return Repository.post(`${resource}/${id}/${line}/extra`, JSON.stringify(extras));
        } catch (error) {
            throw error;
        }
    };
    public static getForProduct = async (
        product_id: number,
        dateFrom: moment.Moment,
        showSales = true
    ): Promise<IBillOfDelivery[]> => {
        try {
            const response = await Repository.get(`${resource}/product/${product_id}`, {
                params: { from: dateFrom.format('DD.MM.YYYY'), sales: showSales },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getForSubscriber = async (
        subscriber_id: number,
        dateFrom: moment.Moment
    ): Promise<IBillOfDelivery[]> => {
        try {
            const response = await Repository.get(`${resource}/subscriber/${subscriber_id}`, {
                params: { from: dateFrom.format('DD.MM.YYYY') },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
