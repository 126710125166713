








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  created(){
    console.log(this.$route.params);
  }

  goBack(){
    this.$router.go(-2);
  }
}
