import moment from 'moment';

export function formatDate(date: Date | string | number): string {
    return moment(date).format('DD.MM.YYYY');
}

export function formatDateTime(date: Date | string | number): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
}

export function formatTime(date: Date | string | number): string {
    return moment(date).format('HH:mm');
}

export function formatDateWithWeek(date: Date | string | number): string {
    const _m = moment(date);
    return _m.format('DD.MM.YYYY (ww)');
}

export function getDateRangePickerLocaleData(): any {
    return {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: 'Použít',
        cancelLabel: 'Zrušit',
        weekLabel: 'T',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
        monthNames: [
            'Leden',
            'Únor',
            'Březen',
            'Duben',
            'Květen',
            'Červen',
            'Červenec',
            'Srpen',
            'Září',
            'Říjen',
            'Listopad',
            'Prosinec',
        ],
        firstDay: 1,
    };
}
