





























































import { Component, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import CTableHeader from '../table/SilTableHeader.vue';
import CTableFooter from '../table/SilTableFooter.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import { SilModalsMixin } from '@/mixins/modals';
import MathUtils from '@/utils/math.utils';
import { IBillOfDelivery, IBillOfDeliveryItem, IOutboundRow } from '@/models/billsofdelivery.models';
import moment from 'moment';
import { BAD_CODES } from '@/utils/scm.utils';
const billOfDeliveryRepo = RepositoryFactory.get('billsofdelivery');

@Component({
    name: 'subscriber-sales-tab',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class SubscriberSalesTab extends Mixins(SilModalsMixin) {
    @Prop(Number) readonly subscriber_id?: number;

    nrOfWeeks = 11;
    weeks: moment.Moment[] = [];

    items: ISubscriberSaleHelper[] = [];

    hidePromoSales = false;
    showInPalletes = false;

    // TABLE
    isLoading = false;
    perPageCount = 50;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;
    currentPage = 1;
    filter = '';
    fields: BvTableFieldArray = [
        {
            key: 'productCode',
            label: 'Kód',
            thClass: 'column-five',
        },
        {
            key: 'productName',
            label: 'Název',
            thClass: 'column-twenty',
        },
    ];

    created() {
        this.generateColumns();
        this.getProductBillsOfDelivery();
    }

    get dynamicFields() {
        return this.fields.filter((x: any) => x.key.startsWith('week_'));
    }

    getWeekSum(x: moment.Moment, items: ISubscriberSaleHelper[]): string {
        const weekNumber = x.week();
        const weekKey = `week_${weekNumber}`;
        const weekKeyDelivered = `${weekKey}_delivered`;
        let ordered = 0;
        let delivered = 0;
        items.forEach((i) => {
            ordered += (i[weekKey] as number) ?? 0;
            delivered += (i[weekKeyDelivered] as number) ?? 0;
        });
        const del = MathUtils.round(delivered, 2);
        const dif = MathUtils.round(ordered - delivered, 2);
        let style = 'color: red; font-weight: bold;';
        if (dif < 0) {
            style = 'color: green; font-weight: 100;';
            if (dif >= -0.01) {
                return '';
            }
        }
        if (dif === 0) {
            return `${del}`;
        }
        return `${del}&nbsp;<span style="${style}">(${dif})</span>`;
    }

    getSlotName(key: string): string {
        return `cell(${key})`;
    }

    roundNumber(x: number) {
        if (x == null) return '';
        return MathUtils.round(x, 2);
    }

    getDiffHTML(ordered: number, delivered: number) {
        if (typeof ordered === 'undefined' && typeof delivered === 'undefined') return '';
        const roundedOrdered = MathUtils.round(ordered, 2);
        const roundedDelivered = MathUtils.round(delivered, 2);
        if (roundedOrdered === roundedDelivered) return '';
        const diff = MathUtils.round(roundedOrdered - roundedDelivered, 2);
        let style = 'color: red;';
        if (diff < 0) {
            style = 'color: green; font-weight: 100;';
            if (diff >= -0.01) {
                return '';
            }
        }
        return `&nbsp;<span style="${style}">(${diff})</span>`;
    }

    private generateColumns() {
        const monday = moment().startOf('week');
        for (let i = 0; i < this.nrOfWeeks + 1; i++) {
            const toAdd = moment(monday).subtract(i, 'weeks');
            this.weeks.push(toAdd);
        }
        this.weeks = this.weeks.reverse();
        this.weeks.forEach((m) => {
            const label = m.week() === monday.week() ? `[${m.week()}]` : `${m.week()}`;
            this.fields.push({
                key: `week_${m.week()}`,
                label,
                thClass: 'text-center column-six',
                tdClass: 'text-center',
                formatter: (value) => {
                    if (!value) return;
                    return MathUtils.round(value, 2);
                },
            });
        });
    }

    private getProductDetailRoute(item: any) {
        if (!item?.product_id) {
            return;
        }
        return {
            name: 'productdetail',
            params: { id: item.product_id },
        };
    }

    private async getProductBillsOfDelivery() {
        try {
            this.isLoading = true;
            const dateFrom = moment().startOf('week').subtract(this.nrOfWeeks, 'weeks');
            const data: IBillOfDelivery[] = await billOfDeliveryRepo.getForSubscriber(this.subscriber_id, dateFrom);
            for (let i = 0; i < data.length; i++) {
                const outboundRows = data[i].outbounds?.map((x) => x.rows)?.flat() ?? [];
                const items = data[i].items ?? [];
                this.fillInItems(items, outboundRows);
            }
            this.items = this.transform(data);
            this.totalRowsCount = this.items.length;
            this.totalUnfilteredRowsCount = this.items.length;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private transform(data: IBillOfDelivery[]) {
        const helpers = new Map<string, ISubscriberSaleHelper>();
        data.forEach((bod) => {
            const documentDate = moment(bod.documentDate);
            const documentWeek = documentDate.week();
            const weekKey = `week_${documentWeek}`;
            const weekKeyDelivered = `week_${documentWeek}_delivered`;
            bod.items?.forEach((item) => {
                const prod_id = (item as any).product?.id ?? item.storecardCode;
                if (!helpers.has(prod_id)) {
                    helpers.set(prod_id, {
                        product_id: prod_id,
                        productCode: (item as any).product?.tplCode ?? item.storecardCode,
                        productName: (item as any).product?.name ?? item.storecardName,
                        productLinked: !!(item as any).product?.id,
                        totalDelivered: 0,
                        total: 0,
                    });
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const helper = helpers.get(prod_id)!;
                const delivered = this.getDeliveredAmount(item, this.showInPalletes ? 'PAL' : 'KRT');
                if (typeof helper[weekKey] !== 'undefined') {
                    helper[weekKey] =
                        (helper[weekKey] as number) +
                        (this.showInPalletes ? item.quantityPalletes : item.quantityCartons);
                } else {
                    helper[weekKey] = this.showInPalletes ? item.quantityPalletes : item.quantityCartons;
                }
                if (typeof helper[weekKeyDelivered] !== 'undefined') {
                    helper[weekKeyDelivered] = (helper[weekKeyDelivered] as number) + delivered;
                } else {
                    helper[weekKeyDelivered] = delivered;
                }
            });
        });
        return Array.from(helpers.values());
    }

    private fillInItems(abraItems: IBillOfDeliveryItem[], tplItems: IOutboundRow[]) {
        const tplItemsClone = JSON.parse(JSON.stringify(tplItems));
        for (let i = 0; i < abraItems.length; i++) {
            const _item = abraItems[i];
            const _tplItems = tplItemsClone.filter((x: any) => x.productCode === _item.storecardCode);
            _item.outbounds = _tplItems;
        }
        const abraItemsClone = JSON.parse(JSON.stringify(abraItems));
        for (let i = 0; i < tplItems.length; i++) {
            const _item = tplItems[i];
            const _abraItem = abraItemsClone.find((x: any) => x.storecardCode === _item.productCode);
            _item.origin = _abraItem;
        }
    }

    private getDeliveredAmount(item: IBillOfDeliveryItem, unit: string): number {
        const outs = item.outbounds ?? [];
        let delivered = 0;
        for (let i = 0; i < outs.length; i++) {
            if (unit === 'PAL') {
                delivered += this.getOutboundAmountPAL(outs[i], item);
            } else {
                delivered += this.getOutboundAmountKRT(outs[i], item);
            }
        }
        return delivered;
    }

    private getOutboundAmountPAL(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityPalletes;
                if (BAD_CODES.includes(item.productCode)) {
                    val = (item.amount * 10) / ratio;
                } else {
                    val = item.amount / ratio;
                }
                break;
            case 'pal':
                val = item.amount;
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private getOutboundAmountKRT(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                val = item.amount;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityCartons;
                val = Math.round((item.amount / ratio) * 100) / 100;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityCartons;
                if (BAD_CODES.includes(item.productCode)) {
                    val = Math.round(((item.amount * 10) / ratio) * 100) / 100;
                } else {
                    val = Math.round((item.amount / ratio) * 100) / 100;
                }
                break;
            case 'pal':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private hidePromoSalesChanged(value: boolean) {
        setTimeout(() => {
            this.getProductBillsOfDelivery();
        }, 0);
    }

    private showInPalletesChanged(value: boolean) {
        setTimeout(() => {
            this.getProductBillsOfDelivery();
        }, 0);
    }
}

interface ISubscriberSaleHelper {
    product_id: number | string;
    productCode: string;
    productName: string;
    productLinked: boolean;
    [key: string]: number | string | boolean;
    total: number;
    totalDelivered: number;
}
