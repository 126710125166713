import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import store from '@/store/store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toasted from 'vue-toasted';
import i18n from '@/i18n/it18n';
import VueSidebarMenu from 'vue-sidebar-menu';
import vSelect from 'vue-select';

import '@/assets/scss/bootstrap.scss';
import '@/assets/scss/sidebar-menu.scss';
import '@/assets/scss/index.scss';

import DateRangePicker from 'vue2-daterange-picker';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import VuePictureSwipe from '@/components/external/photo-swipe/main';
Vue.component('vue-picture-swipe', VuePictureSwipe);

// Set moment locale
import moment from 'moment';
moment.locale('cs');

Vue.use(Loading);
Vue.component('loading', Loading);
Vue.component('v-select', vSelect);
Vue.component('date-range-picker', DateRangePicker);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Vue toast for notif

Vue.use(VueSidebarMenu);

Vue.use(Toasted, { iconPack: 'material' });

Vue.config.productionTip = false;

// import VueProgressBar from 'vue-progressbar';

// const options = {
//   color: '#bffaf3',
//   failedColor: '#874b4b',
//   thickness: '5px',
//   transition: {
//     speed: '0.2s',
//     opacity: '0.6s',
//     termination: 300
//   },
//   autoRevert: true,
//   location: 'left',
//   inverse: false
// }

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
