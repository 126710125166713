
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import CTableHeader from '@/components/table/SilTableHeader.vue';
import CTableFooter from '@/components/table/SilTableFooter.vue';
import { ISubscriber } from '@/models/subscriber.models';

// Import stylesheet

const subscribers = repository.get('subscriber');

@Component({
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class Subscribers extends Vue {
    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    openModal = false;
    filter = '';
    items: ISubscriber[] = [];
    sortByColumn = 'name';
    sortByDesc = false;
    fields: any[] = [
        {
            key: 'identNumber',
            sortable: true,
            label: 'IČO',
        },
        {
            key: 'vatIdentNumber',
            sortable: true,
            label: 'DIČ',
        },
        {
            key: 'name',
            sortable: true,
            label: 'Název',
        },
        {
            key: 'address',
            sortable: true,
            label: 'Sídlo',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscriber) => {
                return (
                    (item.postcode ? `${item.postcode}, ` : '') +
                    (item.city ? `${item.city}, ` : '') +
                    (item.street ? item.street : '')
                );

                return '';
            },
        },
        {
            key: 'country',
            sortable: true,
            label: 'Země',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscriber) => {
                return item.country ?? '';
            },
        },
        {
            key: 'controls',
            sortable: false,
            label: '',
        },
    ];

    public async getSubscribers(): Promise<void> {
        try {
            this.isBusy = true;
            const response: AxiosResponse<ISubscriber[]> = await subscribers.getAll();
            const data: ISubscriber[] = response.data;
            this.totalRows = data.length;
            this.totalUnfilteredRows = data.length;
            this.items = data;
        } catch (error) {
            console.log(error);
        } finally {
            this.isBusy = false;
        }
    }

    // public editUser(data: IGetUsersResponse) {
    //   console.log(data);
    // }
    public filtered(data: any[]) {
        this.totalRows = data.length;
    }

    created() {
        this.getSubscribers();
    }

    private getRouteToDetail(data: ISubscriber) {
        if (!data) return;
        return {
            name: 'subscriberdetail',
            params: {
                id: data.id,
            },
        };
    }
}
