import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';
import { IWarehouse } from '@/models/warehouse.models';

const resource = 'warehouse';

export default class WareHouseRepository {
    public static getAll = async (): Promise<IWarehouse[]> => {
        try {
            const response = await Repository.get(`${resource}/all`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}

