












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'custom-number-input',
})
export default class CustomNumberInput extends Vue {
    @Prop({ type: String }) readonly id!: string;
    @Prop({ type: String }) readonly placeholder!: string;
    @Prop() value!: string;

    isNotANumber = false;

    handleInput(input: string) {
        this.$emit('input', input.replaceAll(',', '.'));
    }

    handleChange(input: string) {
        if (input.trim() === '') {
            this.isNotANumber = false;
            this.$emit('input', '');
            this.$emit('change', '');
            return;
        }
        const parsed = parseFloat(input.replaceAll(',', '.').trim());
        if (isNaN(parsed)) {
            this.isNotANumber = true;
            this.$emit('input', input.replaceAll(',', '.').trim());
            return;
        }
        this.isNotANumber = false;
        this.$emit('input', parsed.toString());
        this.$emit('change', parsed.toString());
    }

    get inputState() {
        if (this.isNotANumber === true) return false;
        return null;
    }
}
