









































import { Component, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import CTableHeader from '../table/SilTableHeader.vue';
import CTableFooter from '../table/SilTableFooter.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import { SilModalsMixin } from '@/mixins/modals';
import { formatDate } from '@/utils/dateUtils';
import MathUtils from '@/utils/math.utils';
import { IBillOfDelivery, IBillOfDeliveryItem, IOutboundRow } from '@/models/billsofdelivery.models';
import { BAD_CODES } from '@/utils/scm.utils';
import moment from 'moment';
import { ISale } from '@/models/sale.models';
import SaleDetailModal from '../sale/SaleDetailModal.vue';

const saleRepo = RepositoryFactory.get('sale');
const billOfDeliveryRepo = RepositoryFactory.get('billsofdelivery');

@Component({
    name: 'product-bills-tab',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
        'sale-detail-modal': SaleDetailModal,
    },
})
export default class ProductBillsTab extends Mixins(SilModalsMixin) {
    @Prop(Number) readonly product_id?: number;

    items: IBillOfDelivery[] = [];

    // Sale Detail
    openDetailModal = false;
    saleDetailID = null;

    // TABLE
    isLoading = false;
    perPageCount = 20;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;
    currentPage = 1;
    filter = '';
    fields: BvTableFieldArray = [
        {
            key: 'documentDate',
            label: 'Datum dodání',
            formatter: (value) => {
                return formatDate(value);
            },
        },
        {
            key: 'displayName',
            label: 'Číslo dokladu',
        },
        {
            key: 'contactName',
            label: 'Odběratel',
        },
        {
            key: 'itemCount',
            label: 'KS',
        },
        {
            key: 'cartonCount',
            label: 'KRT',
        },
        {
            key: 'palleteCount',
            label: 'PAL',
        },
        {
            key: 'serviceLevel',
            label: 'SL Položky',
            formatter: (value) => {
                return value != null ? `${value}%` : '';
            },
        },
        {
            key: 'reference',
            label: 'Reference',
        },
    ];

    created() {
        this.getProductBillsOfDelivery();
    }

    private getContactName(item: IBillOfDelivery) {
        if (!item.contact?.warehouses?.length) return item.contactName;
        const warehouse = item.contact.warehouses[0];
        if (!warehouse?.subscriber) return item.contactName;
        return `${warehouse.subscriber.name} - ${warehouse.name}`;
    }

    private async getProductBillsOfDelivery() {
        try {
            this.isLoading = true;
            const dateFrom = moment().startOf('week').subtract(3, 'months');
            let sales: ISale[] = [];
            try {
                sales = await saleRepo.getSalesForProduct(this.product_id, dateFrom);
            } catch (e) {
                console.error('There was an error fetching sale data.');
                console.error(e);
            }
            const data: IBillOfDelivery[] = await billOfDeliveryRepo.getForProduct(this.product_id, dateFrom);

            for (let i = 0; i < data.length; i++) {
                const outboundRows = data[i].outbounds?.map((x) => x.rows)?.flat() ?? [];
                const items = data[i].items ?? [];
                const info = this.fillInItems(items, outboundRows);
                data[i].serviceLevel = info.serviceLevel;
            }

            this.items = data.map((x) => ({ ...x, ...this.precalculate(x, sales) }));
            this.totalRowsCount = data.length;
            this.totalUnfilteredRowsCount = data.length;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private precalculate(x: IBillOfDelivery, sales: ISale[]) {
        let itemCount = 0;
        let cartonCount = 0;
        let palleteCount = 0;
        x.items?.forEach((i) => {
            itemCount += i.quantity ?? 0;
            cartonCount += i.quantityCartons ?? 0;
            palleteCount += i.quantityPalletes ?? 0;
        });
        const documentDate = moment(x.documentDate);
        const itemSales = sales.filter(
            (s) =>
                !moment(s.dateANC).isAfter(documentDate) &&
                !moment(s.dateFinish).isSameOrBefore(documentDate) &&
                s.subscriber_id === x.subscriber?.id
        );
        return {
            itemCount: MathUtils.round(itemCount, 2),
            cartonCount: MathUtils.round(cartonCount, 2),
            palleteCount: MathUtils.round(palleteCount, 2),
            sales: itemSales ?? [],
        };
    }

    private fillInItems(abraItems: IBillOfDeliveryItem[], tplItems: IOutboundRow[]) {
        const tplItemsClone = JSON.parse(JSON.stringify(tplItems));
        const totalSLHelper: { delivered: number; ordered: number } = { delivered: 0, ordered: 0 };
        for (let i = 0; i < abraItems.length; i++) {
            const _item = abraItems[i];
            const _tplItems = tplItemsClone.filter((x: any) => x.productCode === _item.storecardCode);
            _item.outbounds = _tplItems;
        }
        const abraItemsClone = JSON.parse(JSON.stringify(abraItems));
        for (let i = 0; i < tplItems.length; i++) {
            const _item = tplItems[i];
            const _abraItem = abraItemsClone.find((x: any) => x.storecardCode === _item.productCode);
            _item.origin = _abraItem;
        }
        for (let i = 0; i < abraItems.length; i++) {
            const slHelper = this.getItemServiceLevelHelper(abraItems[i]);
            totalSLHelper.delivered += slHelper.delivered;
            totalSLHelper.ordered += slHelper.ordered;
        }
        const sl = Math.round((totalSLHelper.delivered / totalSLHelper.ordered) * 10000) / 100;
        return {
            serviceLevel: sl,
        };
    }

    private getItemServiceLevelHelper(item: IBillOfDeliveryItem): { delivered: number; ordered: number } {
        const outs = item.outbounds ?? [];
        const ordered = item.quantityCartons ?? 0;
        let delivered = 0;
        for (let i = 0; i < outs.length; i++) {
            delivered += this.getOutboundAmountKRT(outs[i], item);
        }
        return {
            ordered,
            delivered,
        };
    }

    private getOutboundAmountKRT(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                val = item.amount;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityCartons;
                val = Math.round((item.amount / ratio) * 100) / 100;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityCartons;
                if (BAD_CODES.includes(item.productCode)) {
                    val = Math.round(((item.amount * 10) / ratio) * 100) / 100;
                } else {
                    val = Math.round((item.amount / ratio) * 100) / 100;
                }
                break;
            case 'pal':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private closeDetailModal() {
        this.openDetailModal = false;
        this.saleDetailID = null;
    }

    private showDetailModal(row: any) {
        if (!row) return;
        console.log(row);
        this.saleDetailID = row.id;
        this.openDetailModal = true;
    }
}
