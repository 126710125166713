






















































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { IWarehouse } from '@/models/warehouse.models';
import { createLogger } from 'vuex';
import { IOrderItem, IOrder } from '@/models/order.models';
import { IProduct } from '@/models/product.models';
import { ISupplier } from '@/models/supplier.models';
import { ISale, ISaleItem } from '@/models/sale.models';
import { IStoreCard, IStoreCardUnit } from '@/models/storecard.models';
import moment from 'moment';
import { ISubscriber } from '@/models/subscriber.models';

const importerRepo = RepositoryFactory.get('importer');
const saleRepo = RepositoryFactory.get('sale');
const productRepo = RepositoryFactory.get('product');

@Component({
  name: 'add-sale-item-modal',
})
export default class NewSaleModal extends Vue {
  private isLoading = false;
  private sale: ISaleItem = {} as ISaleItem;
  private products: IProduct[] = [];
  private productSelected: IProduct | null = null;

  // states
  private priceState: boolean | null = null;

  @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;
  @Prop() sale_id?: number;

  created() {
    this.getInitData();
  }

  private async addSaleItem(): Promise<void> {
    try {
      if (!this.sale_id) throw new Error('Sale ID not defined!');
      this.isLoading = true;
      const saleItem: ISaleItem = {
        ...this.sale,
        sale_id: this.sale_id,
        product_id: this.productSelected?.id ?? -1,
        price: parseFloat(
          this.sale.price?.toString().replaceAll(',', '.') ?? ''
        ),
      };
      const res: AxiosResponse<ISale> = await saleRepo.addItem(
        this.sale_id,
        saleItem
      );
      this.$emit('close');
    } catch (error) {
      if (error?.data?.msg) {
        let errMsg = error.data.msg;
        if (errMsg === 'SALE_ITEM_EXISTS')
          errMsg = 'K letákové akci je již tento produkt přiřazený.';
        this.$toasted.error(errMsg, {
          fullWidth: true,
          action: [],
          fitToScreen: true,
          closeOnSwipe: true,
          duration: 10000,
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async getInitData(): Promise<void> {
    try {
      this.isLoading = true;
      await this.getProducts();
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  private async getProducts(): Promise<void> {
    try {
      const res: AxiosResponse<IProduct[]> = await productRepo.getAll();
      const data: IProduct[] = res.data;
      this.products = data;
    } catch (error) {}
  }

  private priceChanged() {
    if (this.priceState === false) {
      this.priceState = true;
    }
  }
}
