import Repository from '@/api/Repository';

const resource = 'crm/stock';

export default class StockRepository {
    public static getStockState = async (): Promise<any> => {
        try {
            const response = await Repository.get(`${resource}/last`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
