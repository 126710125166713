





































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';

import Val from '@/mixins/validators';
import { ILoginParams } from '@/models/user.models';
// Import stylesheet

const users = repository.get('user');

@Component
export default class Login extends Mixins(Val) {
    login = '';
    password = '';
    fullPage = false;
    isLoading = false;

    errors: any = {
        login: [],
        password: [],
    };

    redirectPath = '';

    displayErrorInput(input: string): boolean | null {
        if (this.errors[input].length === 0) {
            return null;
        } else {
            return false;
        }
    }

    @Watch('login')
    onLoginChanged(_: any, newVal: string) {
        if (newVal.length > 0) {
            this.errors.login = [];
        }
    }

    @Watch('password')
    onPasswordChanged(_: any, newVal: string) {
        if (newVal.length > 0) {
            this.errors.password = [];
        }
    }

    async onSubmit() {
        try {
            this.errors = {
                login: [],
                password: [],
            };
            const err = this.validateForm();
            if (!err) {
                this.isLoading = true;
                const loginParams: ILoginParams = {
                    userName: this.login,
                    password: this.password,
                };
                await this.$store.dispatch('login', loginParams);
                if (this.redirectPath) {
                    this.$router.push({ path: this.redirectPath });
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
                // window.location.reload(); // TODO: FUCK THE UNDEFINED INPUT-GROUP SLOTS
                // setTimeout(() => { window.location.reload();}, 500);
            } else {
                const errText: string = Object.keys(this.errors)
                    .map((e) => {
                        return `${this.errors[e][0] ? this.errors[e][0] + '<br>' : ''}`;
                    })
                    .join()
                    .replace(/,/g, '');
                this.$toasted.clear();
                this.$toasted.error(errText, {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
            }
        } catch (error) {
            this.$toasted.clear();
            this.$toasted.show(this.$t('login.validation.incorrectData') as never, {
                position: 'top-center',
                fullWidth: true,
                fitToScreen: true,
                type: 'error',
                duration: 10000,
            });
        } finally {
            this.password = '';
            this.isLoading = false;
        }
    }

    validateForm() {
        if (!this.$data.$val.empty(this.login)) {
            this.errors.login.push(this.$t('login.validation.empty.login') as never);
        }
        if (!this.$data.$val.empty(this.password)) {
            this.errors.password.push(this.$t('login.validation.empty.password') as never);
        }
        const err = Object.keys(this.errors).find((e) => {
            return this.errors[e].length;
        });

        console.log(err);

        return err ? this.errors : null;
    }

    onCancel() {
        console.log('CANCEL');
    }

    created() {
        if (this.$route.query) {
            this.redirectPath = this.$route?.query?.path as string;
        }
    }
}
