

































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { ISale, ISaleItem } from '@/models/sale.models';
import GalleryWidget from '../gallery/GalleryWidget.vue';
import CustomNumberInput from '../helpers/number.input.vue';
import RoleUtils from '@/utils/role.utils';

const importerRepo = RepositoryFactory.get('importer');
const saleRepo = RepositoryFactory.get('sale');
const productRepo = RepositoryFactory.get('product');

@Component({
    name: 'sale-detail-modal',
    components: {
        'gallery-widget': GalleryWidget,
        'custom-number-input': CustomNumberInput,
    },
})
export default class SaleDetailModal extends Vue {
    private isLoading = false;

    private sale: ISale | null = null;
    private modalTitle = '';

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;
    @Prop() sale_id?: number;

    //
    itemsChanged = false;
    itemChanges: any = {};
    //

    //
    hasSCMRole = false;
    SCMBaseURL = '';
    //

    private fields = [
        {
            key: 'product.tplCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'product.name',
            sortable: true,
            label: 'Sortiment',
        },
        {
            key: 'promoPrice',
            label: 'Akční cena vč. DPH',
        },
    ];

    created() {
        this.SCMBaseURL = process.env.VUE_APP_SCM_URL;
        this.checkRoles();
        this.getSale();
    }

    async checkRoles() {
        this.hasSCMRole = await RoleUtils.isInRole('USER_SCM');
    }

    private async getSale(silent?: boolean): Promise<void> {
        let reloadNeeded = false;
        try {
            if (!silent) this.isLoading = true;
            const sale: ISale = await saleRepo.getById(this.sale_id);
            this.sale = sale;
            this.modalTitle = `${sale.subscriber?.name}\n${sale.saleNumber} - ${sale.name}`;
            if (!this.sale.folder_id) {
                await saleRepo.createFolder(this.sale.id);
                reloadNeeded = true;
            }
        } catch (error) {
            console.log(error);
        } finally {
            // TODO: Rework this that you dont need to call the getSale again since
            // createFolder will return the actuall created folder :)
            if (reloadNeeded) {
                this.getSale();
            } else {
                this.isLoading = false;
            }
        }
    }

    private closeModal() {
        this.syncedOpenModal = false;
    }

    private markChanged(id: number, type: string): void {
        this.itemsChanged = true;
        if (!this.itemChanges[id]) this.itemChanges[id] = {};
        if (type === 'promoPrice') {
            this.itemChanges[id].promoPrice = true;
        } else {
            console.error('Type not defined!');
        }
        this.$forceUpdate();
    }

    private async saveChanged() {
        const item_ids: number[] = Object.keys(this.itemChanges).map((id) => Number(id));
        const toUpdate: ISaleItem[] = [];
        for (let i = 0; i < item_ids.length; i++) {
            const currentItem: ISaleItem | undefined = this.sale?.items?.find((item) => item.id === item_ids[i]);
            if (currentItem) {
                toUpdate.push({
                    id: item_ids[i],
                    promoPrice: currentItem.promoPrice,
                } as ISaleItem);
            }
        }
        try {
            await saleRepo.updatePromoPrices(this.sale_id, toUpdate);
            this.itemsChanged = false;
            this.itemChanges = {};
            this.$toasted.success('Položky upraveny.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 10000,
            });
            this.getSale(true);
        } catch (e) {
            console.error(e);
        }
    }
}
