
import { Component, Mixins, Vue } from 'vue-property-decorator';

import ip from 'is-ip';
@Component
export default class Vals extends Vue {
    $val = {
        alphanum(text: string) {
            return /^[a-z0-9]+$/i.test(text);
        },
        empty(text: string) {
            return text.length > 0;
        },
        number(text: string | number) {
            return typeof text === "number";
        },
        minMaxLength(text: string, min: number, max: number) {
            return text.length >= min && text.length <= max
        },

        email(text: string) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(text);
        },
        minMaxNumber(text: number, min: number, max: number) {

            return Number(text) >= min && Number(text) <= max
        },
        isIP(text: string) {
            return ip(text);
        }
    }
}