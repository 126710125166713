import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';
import { ISupplier } from '@/models/supplier.models';

import service from '@/api/api.services';
import { IFolder } from '@/models/gallery.models';
import { IProduct } from '@/models/product.models';

const resource = 'supplier';

export default class SupplierRepository {
    public static getAll = async (): Promise<AxiosResponse<ISupplier[]>> => {
        try {
            return Repository.get(`${resource}`);
        } catch (error) {
            throw error;
        }
    };
    public static create = async (supplier: ISupplier): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.post(`${resource}`, JSON.stringify(supplier));
        } catch (error) {
            throw error;
        }
    };
    public static update = async (supplier: ISupplier): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.put(`${resource}/${supplier.id}`, JSON.stringify(supplier));
        } catch (error) {
            throw error;
        }
    };
    public static delete = async (id: number): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.delete(`${resource}/${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static get = async (id: string): Promise<AxiosResponse<ISupplier>> => {
        try {
            return Repository.get(`${resource}/${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static getListing = async (id: string): Promise<IProduct[]> => {
        try {
            const response = await Repository.get(`${resource}/${id}/listing`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static listProduct = async (id: string, product: string): Promise<AxiosResponse<ISupplier>> => {
        try {
            return Repository.post(`${resource}/${id}/listproduct`, JSON.stringify({ product: product }));
        } catch (error) {
            throw error;
        }
    };
    public static createFolder = async (id: number): Promise<IFolder> => {
        try {
            const response = await Repository.post(`${resource}/${id}/folder`, {});
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static notificationTest = async (id: number, hookUrl: string): Promise<IFolder> => {
        try {
            const response = await Repository.post(`${resource}/${id}/notification-test`, { hookUrl });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}

