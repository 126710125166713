
import { Component, Vue, Mixins } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { formatDateTime } from '@/utils/dateUtils';
import { ErrorController } from '@/utils/error.controller';
import { IProduct } from '@/models/product.models';
import { ISubscriber, ISubscribersProducts, ISubscriberWarehouse } from '@/models/subscriber.models';
import { SilModalsMixin } from '@/mixins/modals';
import { IContact, IContactAddress } from '@/models/contact.models';
import CTableHeader from '@/components/table/SilTableHeader.vue';
import CTableFooter from '@/components/table/SilTableFooter.vue';
import { customParseFloat, customParseInt } from '@/utils/number.utils';
import moment from 'moment';
import SubscriberBillsTab from '@/components/subscriber/SubscriberBillsTab.vue';
import SubscriberSalesTab from '@/components/subscriber/SubscriberSalesTab.vue';
import SubscriberPromoTab from '@/components/subscriber/SubscriberPromoTab.vue';

const subscribers = repository.get('subscriber');
const products = repository.get('product');

@Component({
    components: {
        'subscriber-bills-tab': SubscriberBillsTab,
        'subscriber-sales-tab': SubscriberSalesTab,
        'subscriber-promo-tab': SubscriberPromoTab,
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class SubscriberDetail extends Mixins(SilModalsMixin) {
    private subscriber: ISubscriber | null = null;
    private subscriberWarehouses: ISubscriberWarehouse[] | null = null;
    private isLoading = false;
    private openModal = false;
    private openModal2 = false;
    // LISTING
    private openListingModal = false;
    private productsForListing: IProduct[] = [];
    private productForListingSelected: IProduct | null = null;
    private listingFilter = '';
    private listingChanged = false;
    private listingPerPage = 20;
    private listingTotalRows = 0;
    private listingCurrentPage = 1;
    private listingTotalUnfilteredRows = 0;
    private listingSortBy = 'listing.createdAt';
    private listingSortDesc = true;

    private tabIndex = 2;
    private tabMap: any = {
        reservations: 4,
    };

    // END LISTING
    private idEditingWarehouse: number | null = null;
    private subscriberWarehousesFields: any[] = [
        {
            key: 'idAbra',
            sortable: true,
            label: 'Kód',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscriberWarehouse) => {
                if (item.contacts?.length) {
                    const contact: IContact = item.contacts[0];
                    return contact.code;
                }
                return '';
            },
        },
        {
            key: 'tplCode',
            sortable: true,
            label: 'TPL ID',
        },
        {
            key: 'warehouseName',
            sortable: true,
            label: 'Název',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscriberWarehouse) => {
                if (item.contacts?.length) {
                    const contact: IContact = item.contacts[0];
                    return contact.name;
                }
                return '';
            },
        },
        {
            key: 'address',
            sortable: true,
            label: 'Adresa',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscriberWarehouse) => {
                if (item.contacts?.length && item.contacts[0].addresses?.length) {
                    const address: IContactAddress = item.contacts[0].addresses[0];
                    return (
                        (address.postcode ? `${address.postcode}, ` : '') +
                        (address.city ? `${address.city}, ` : '') +
                        (address.street ? address.street : '')
                    );
                }
                return '';
            },
        },
    ];
    private subscriberProductsFields: any[] = [
        {
            key: 'tplCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'name',
            sortable: true,
            label: 'Název',
        },
        {
            key: 'listingShelfLife',
            sortable: true,
            label: 'Garant. DMT',
            formatter: (value: any, key: any, item: IProduct) => {
                if (item.listing) {
                    return item.listing.itemShelfLife;
                }
                return undefined;
            },
            filterByFormatted: true,
            sortByFormatted: true,
        },
        {
            key: 'itemShelfLife',
            sortable: true,
            label: 'Celková DMT',
        },
        {
            key: 'listing.createdAt',
            sortable: true,
            label: 'Zalistováno',
            filterByFormatted: true,
            formatter: (value: any, key: any, item: IProduct) => {
                if (item.listing?.createdAt) {
                    return formatDateTime(item.listing.createdAt);
                }
                return undefined;
            },
            sortByFormatted: (value: any, key: any, item: IProduct) => {
                if (item.listing) {
                    return moment(item.listing.createdAt)?.toDate()?.getTime();
                }
                return undefined;
            },
        },
    ];

    private goBack() {
        this.$router.back();
    }

    private editSubscriber() {
        this.openModal = true;
    }

    private editSubscriberWarehouse(subscriberWarehouse: ISubscriberWarehouse) {
        this.idEditingWarehouse = subscriberWarehouse.id ?? null;
        this.openModal2 = true;
    }

    private async deleteSubscriber() {
        try {
            const allow: boolean = await this.$delete_confirm('Přejete si opravdu odstranit odběratele?');
            if (allow) {
                const res: AxiosResponse<boolean> = await subscribers.delete(this.subscriber?.id);
                this.goBack();
            }
        } catch (error) {}
    }

    private async deleteWarehouse(idWarehouse: number): Promise<void> {
        const allow: boolean = await this.$delete_confirm(`Opravdu odstranit sklad?`);
        if (allow) {
            try {
                this.isLoading = true;
                await subscribers.deleteWarehouse(this.subscriber?.id, idWarehouse);
                await this.getSubscriber();
                await this.getSubscriberWarehouses();
            } catch (error) {
                ErrorController.showErrorToast(error as AxiosResponse<any>);
            } finally {
                this.isLoading = false;
            }
        }
    }

    private closeModal() {
        this.openModal = false;
    }

    private closeSaveModal() {
        this.openModal = false;
        // setTimeout(() => this.getSubscribers(), 50);
        this.getSubscriber();
    }

    private closeModal2() {
        this.openModal2 = false;
        this.idEditingWarehouse = null;
    }

    private closeSaveModal2() {
        this.openModal2 = false;
        // setTimeout(() => this.getSubscribers(), 50);
        this.getSubscriberWarehouses();
    }

    private async getSubscriber() {
        try {
            const idSubscriber: string = this.$route.params.id;
            const res: AxiosResponse<ISubscriber> = await subscribers.get(idSubscriber);
            const data = res.data;
            this.subscriber = data;
            this.listingTotalRows = data.products?.length ?? 0;
            this.listingTotalUnfilteredRows = data.products?.length ?? 0;
        } catch (_) {}
    }

    private async getSubscriberWarehouses() {
        try {
            const idSubscriber: string = this.$route.params.id;
            const res: AxiosResponse<ISubscriberWarehouse[]> = await subscribers.getWarehouses(idSubscriber);
            const data: ISubscriberWarehouse[] = res.data;
            this.subscriberWarehouses = data;
        } catch (_) {}
    }

    async created() {
        await Promise.all([this.getSubscriber(), this.getSubscriberWarehouses()]);
        this.handleQuery();
    }

    private handleQuery() {
        const query = this.$route.query;
        if (query?.tab) {
            const idx = this.tabMap[query.tab as string];
            if (idx && idx >= 0) {
                this.tabIndex = idx;
            }
        }
    }

    private getRouteToProduct(item: IProduct) {
        return {
            name: 'productdetail',
            params: {
                id: item.id,
                productName: item.name,
                from: this.$route.name ?? '',
            },
        };
    }

    private listingModalSaved() {
        this.openListingModal = false;
        this.getSubscriber();
        this.getSubscriberWarehouses();
        this.$toasted.success('Položky zalistovány.', {
            fullWidth: true,
            action: [],
            fitToScreen: true,
            closeOnSwipe: true,
            duration: 5000,
        });
    }

    private async loadListingData() {
        if (!this.productsForListing?.length) {
            await this.getProducts();
        }
    }

    private async addListing(data: IProduct) {
        if (this.subscriber) {
            try {
                await subscribers.addListing(this.subscriber.id, {
                    subscriber_id: this.subscriber.id,
                    product_id: data.id,
                    itemShelfLife: null,
                } as ISubscribersProducts);
                await this.getSubscriber();
                this.$toasted.success('Zalistováno.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 5000,
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.productForListingSelected = null;
            }
        }
    }

    private async getProducts(): Promise<void> {
        try {
            const res: AxiosResponse<IProduct[]> = await products.getAll();
            const data: IProduct[] = res.data;
            this.productsForListing = data;
        } catch (error) {}
    }

    private get productsForListingOptions() {
        return (
            this.productsForListing?.map((p) => ({
                ...p,
                searchName: `${p.tplCode} - ${p.name}`,
            })) || []
        );
    }

    private markChanged(data: any) {
        this.listingChanged = true;
        data.changed = true;
    }

    private async saveListingChanges() {
        if (!this.subscriber) return;
        const changedProducts = this.subscriber.products?.filter((p: any) => p.changed);
        if (!changedProducts?.length) return;
        try {
            for (let i = 0; i < changedProducts.length; i++) {
                const product: IProduct = changedProducts[i];
                const shelfLife = customParseInt(product.listing?.itemShelfLife);
                const price = customParseFloat(product.listing?.price);
                try {
                    await subscribers.updateListing(this.subscriber.id, {
                        subscriber_id: this.subscriber.id,
                        product_id: product.id,
                        itemShelfLife: shelfLife,
                        price: price,
                    } as ISubscribersProducts);
                } catch (e) {}
            }
            await this.getSubscriber();
            this.listingChanged = false;
            this.$toasted.success('Změny uloženy.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 5000,
            });
        } catch (e) {
            console.error(e);
        }
    }

    private async removeListing(product: IProduct) {
        if (this.subscriber?.id && product?.id) {
            const shouldDelete: boolean = await this.$delete_confirm(`Opravdu chcete odstranit listing?`);
            if (!shouldDelete) return;
            try {
                await subscribers.deleteListing(this.subscriber?.id, {
                    subscriber_id: this.subscriber.id,
                    product_id: product.id,
                } as ISubscribersProducts);
                this.getSubscriber();
            } catch (e) {}
        }
    }

    private focusNext(event: any) {
        event?.target?.blur();
        // TODO: Try to focus next
    }

    private automaticListingHandler() {
        this.openListingModal = true;
    }
}
