























import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component({
    name: 'sil-table-header',
})
export default class CTableHeader extends Vue {
    @PropSync('perPage', { type: Number }) syncedPerPage!: number;
    @Prop({ default: () => [10, 20, 30, 50, 100, 200] })
    readonly perPageOptions!: number[];
    @PropSync('filter', { type: String }) syncedFilter!: string;
}
