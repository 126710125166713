
import RepositoryFactory from '@/api/RepositoryFactory';
import { INotification } from '@/models/notification.models';
import { formatDateTime } from '@/utils/dateUtils';
import { BvTableFieldArray } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import CTableFooter from '../table/SilTableFooter.vue';
import CTableHeader from '../table/SilTableHeader.vue';

const notificationRepo = RepositoryFactory.get('notification');

@Component({
    name: 'notification-card',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class NotificationCard extends Vue {
    isLoading = false;
    filter = '';
    currentPage = 1;
    perPageCount = 10;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;

    notifications: INotification[] = [];
    fields: BvTableFieldArray = [
        {
            key: 'title',
            label: 'Titulek',
        },
        {
            key: 'body',
            label: 'Zpráva',
        },
        {
            key: 'created_at',
            label: 'Vytvořeno',
            formatter: (value) => {
                return formatDateTime(value);
            },
        },
        {
            key: 'controls',
            label: '',
        },
    ];
    created() {
        this.getNotifications();
    }
    async getNotifications() {
        try {
            this.notifications = await notificationRepo.list();
            this.totalRowsCount = this.notifications.length;
            this.totalUnfilteredRowsCount = this.notifications.length;
        } catch (e) {
            console.log(e);
        }
    }
    private handleNotificationClick(item: INotification) {
        if (!item || !item.handler) return;
        const [type, id] = item.handler.split(':');
        switch (type) {
            case 'BILL_OF_DELIVERY':
                this.$router.push({
                    name: 'billsofdeliverydetail',
                    params: {
                        id,
                    },
                });
                break;
            default:
                break;
        }
    }
}
