

























import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({
  name: 'sil-table-footer',
})
export default class CTableFooter extends Vue {
  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number;
  @PropSync('perPage', { type: Number }) syncedperPage!: number;
  @PropSync('totalRows', { type: Number }) syncedtotalRows!: number;
  @PropSync('totalUnfilteredRows', { type: Number }) syncedtotalUnfilteredRows!: number;
}
