






























































import { Component, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import CTableHeader from '../table/SilTableHeader.vue';
import CTableFooter from '../table/SilTableFooter.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import { SilModalsMixin } from '@/mixins/modals';
import moment from 'moment';
import { ISale } from '@/models/sale.models';
import { formatDate } from '@/utils/dateUtils';
import { SaleState } from '../sale/NewSale.vue';
import SaleDetailModal from '../sale/SaleDetailModal.vue';

const saleRepo = RepositoryFactory.get('sale');

@Component({
    name: 'product-promo-tab',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
        'sale-detail-modal': SaleDetailModal,
    },
})
export default class ProductPromoTab extends Mixins(SilModalsMixin) {
    @Prop(Number) readonly product_id?: number;

    items: ISale[] = [];
    unfilteredItems: ISale[] = [];

    showEndedSales = false;
    sortBy = 'daysRemaining';
    sortDesc = false;

    // Sale Detail
    openDetailModal = false;
    saleDetailID = null;

    // TABLE
    isLoading = false;
    perPageCount = 50;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;
    currentPage = 1;
    filter = '';
    fields: BvTableFieldArray = [
        {
            key: 'saleNumber',
            label: 'Číslo',
            sortable: true,
        },
        {
            key: 'importer.name',
            label: 'Importér',
            sortable: true,
        },
        {
            key: 'subscriberName',
            label: 'Odběratel',
            sortable: true,
            sortByFormatted: (value: any, key: any, item: any) => {
                return item.subscriber?.name ?? '';
            },
        },
        {
            key: 'name',
            label: 'Název',
            sortable: true,
        },
        {
            key: 'dateANC',
            label: 'ANC',
            sortable: true,
            filterByFormatted: true,
            formatter: (value: string) => {
                return formatDate(value);
            },
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'dateStart',
            label: 'Od',
            sortable: true,
            filterByFormatted: true,
            formatter: (value: string) => {
                return formatDate(value);
            },
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'dateFinish',
            label: 'Do',
            sortable: true,
            filterByFormatted: true,
            formatter: (value: string) => {
                return formatDate(value);
            },
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'daysRemaining',
            label: 'Zbývá',
            sortable: true,
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: any, key: any, item: ISale) => {
                const now = moment().startOf('day');
                const finish = moment(item.dateFinish).endOf('day');
                const diff = Math.ceil(finish.diff(now, 'hours') / 24);
                return Math.max(diff, 0);
            },
        },
        {
            key: 'status',
            label: 'Stav',
            sortable: true,
            formatter: (value: string, key: any, item: ISale) => {
                if (value === 'ACTIVE') {
                    const now = new Date();
                    const dateStart = new Date(item.dateANC);
                    if (dateStart > now) {
                        return Object(SaleState)[item.status];
                    } else {
                        const dateEnd = new Date(item.dateFinish);
                        if (dateEnd >= now) {
                            return 'Aktivní';
                        } else {
                            return 'Ukončená';
                        }
                    }
                }
                return Object(SaleState)[value];
            },
        },
    ];

    created() {
        this.getSubscriberSales();
    }

    private formatDate(date: string) {
        return formatDate(date);
    }

    private async getSubscriberSales() {
        try {
            this.isLoading = true;
            const dateFrom = moment(0);
            const data: ISale[] = await saleRepo.getSalesForProduct(this.product_id, dateFrom);
            this.unfilteredItems = data;
            this.totalUnfilteredRowsCount = this.unfilteredItems.length;
            this.items = data.filter((x) => this.customFilterFunction(x, this.showEndedSales));
            this.totalRowsCount = this.items.length;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private customFilterFunction(item: ISale, showEnded: boolean) {
        const ended = new Date(item.dateFinish) < new Date() && item.status !== 'CONCEPT';
        if (showEnded === false && ended) {
            return false;
        }
        return true;
    }

    private showEndedSalesChanged(val: boolean) {
        this.items = this.unfilteredItems.filter((x) => this.customFilterFunction(x, val));
        this.totalRowsCount = this.items.length;
    }

    private getRouteToSale(row: any) {
        if (!row) return;
        return {
            name: 'saledetail',
            params: { id: row.id, saleName: row.name },
        };
    }

    private getRouteToSubscriber(item: ISale) {
        return {
            name: 'subscriberdetail',
            params: {
                id: item.subscriber_id?.toString() ?? '#',
                subscriberName: item.subscriber?.name ?? '#',
                from: this.$route.name ?? '',
            },
        };
    }

    private closeDetailModal() {
        this.openDetailModal = false;
        this.saleDetailID = null;
    }

    private showDetailModal(row: any) {
        if (!row) return;
        console.log(row);
        this.saleDetailID = row.id;
        this.openDetailModal = true;
    }
}
