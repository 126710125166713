

























































































































































import { Component, Vue, PropSync } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { IWarehouse } from '@/models/warehouse.models';
import { createLogger } from 'vuex';
import { IOrderItem, IOrder } from '@/models/order.models';
import { IProduct } from '@/models/product.models';
import { ISupplier } from '@/models/supplier.models';
import { ISale } from '@/models/sale.models';
import { IStoreCard, IStoreCardUnit } from '@/models/storecard.models';
import moment from 'moment';
import { ISubscriber } from '@/models/subscriber.models';

const warehouses = RepositoryFactory.get('warehouse');
const subscriberRepo = RepositoryFactory.get('subscriber');
const saleRepo = RepositoryFactory.get('sale');
const supplierRepo = RepositoryFactory.get('supplier');

export enum SaleState { //TODO: Lokalizovat backendově
    CONCEPT = 'Koncept',
    ACTIVE = 'Plánovaná',
}

@Component({
    name: 'new-sale-modal',
})
export default class NewSaleModal extends Vue {
    isLoading = false;
    sale: ISale = {
        chargeCurrency: 'CZK',
    } as ISale;
    suppliers: ISupplier[] = [];
    supplierSelected: ISupplier | null = null;
    supplierSelectState: boolean | null = null;
    wareHouses: IWarehouse[] = [];
    wareHouseSelected: IWarehouse | null = null;
    subscribers: ISubscriber[] = [];
    subscriberSelected: ISubscriber | null = null;
    saleStateSelected: string | null = Object.keys(SaleState)[0];
    chargeState: boolean | null = null;
    wareHouseSelectState: boolean | null = null;
    subscriberSelectState: boolean | null = null;
    dateANCPickerState: boolean | null = null;
    dateStartPickerState: boolean | null = null;
    dateFinishPickerState: boolean | null = null;
    saleNameState: boolean | null = null;
    currencies: any[] = [
        { text: 'CZK', value: 'CZK' },
        { text: 'EUR', value: 'EUR' },
    ];

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;

    created() {
        this.getInitData();
    }

    wareHouseChanged() {
        if (this.wareHouseSelectState === false) {
            this.wareHouseSelectState = true;
        }
    }
    dateANCChanged() {
        if (this.dateANCPickerState === false) {
            this.dateANCPickerState = true;
        }
    }
    dateStartChanged() {
        if (this.dateStartPickerState === false) {
            this.dateStartPickerState = true;
        }
    }
    dateFinishChanged() {
        if (this.dateFinishPickerState === false) {
            this.dateFinishPickerState = true;
        }
    }

    get suppliersOptions() {
        return this.suppliers.map((s) => ({ ...s, searchName: s.contact?.name }));
    }

    supplierChanged(): void {
        if (this.supplierSelectState === false) {
            this.supplierSelectState = true;
        }
    }

    validateSale(): boolean {
        const errors: string[] = [];
        if (!this.wareHouseSelected) {
            errors.push('Importér musí být vybrán');
            this.wareHouseSelectState = false;
        } else {
            this.wareHouseSelectState = true;
        }
        if (!this.subscriberSelected) {
            errors.push('Odběratel musí být vybrán');
            this.subscriberSelectState = false;
        } else {
            this.subscriberSelectState = true;
        }
        // if (!this.supplierSelected) {
        //     errors.push('Dodavatel musí být vybrán');
        //     this.supplierSelectState = false;
        // } else {
        //     this.supplierSelectState = true;
        // }
        this.supplierSelectState = true;
        if (!this.sale.name) {
            errors.push('Název akce musí být vyplněn');
            this.saleNameState = false;
        } else {
            this.saleNameState = true;
        }
        if (!this.sale.dateANC) {
            errors.push('Datum ANC musí být vybrán');
            this.dateANCPickerState = false;
        } else {
            this.dateANCPickerState = true;
        }
        if (!this.sale.dateStart) {
            errors.push('Datum začátku akce musí být vybrán');
            this.dateStartPickerState = false;
        } else {
            this.dateStartPickerState = true;
        }
        if (!this.sale.dateFinish) {
            errors.push('Datum konce akce musí být vybrán');
            this.dateFinishPickerState = false;
        } else {
            this.dateFinishPickerState = true;
        }
        if (this.sale.chargeAmount) {
            const ca = Number.parseFloat(this.sale.chargeAmount.toString().replaceAll(',', '.'));
            if (isNaN(ca)) {
                errors.push('Poplatek musí být číslo');
                this.chargeState = false;
            } else {
                this.chargeState = true;
            }
        } else {
            this.chargeState = null;
        }
        if (!this.sale.chargeCurrency) {
            errors.push('Měna poplatku musí být vybrána');
        }
        if (errors.length) {
            const errorText = errors.reduce((a: string, b: string): string => {
                return `${a}<br>${b}`;
            });
            this.$toasted.error(errorText, {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 10000,
            });
        }
        return errors.length === 0;
    }

    async saveSale(): Promise<void> {
        try {
            const valid: boolean = this.validateSale();
            if (valid) {
                this.isLoading = true;
                const sale: ISale = {
                    ...this.sale,
                    importer_id: this.wareHouseSelected?.importer_id,
                    warehouse_id: this.wareHouseSelected?.id,
                    subscriber_id: this.subscriberSelected?.id,
                    supplier_id: this.supplierSelected?.id ?? null,
                    status: this.saleStateSelected ?? Object.keys(SaleState)[0],
                };
                const data: ISale = await saleRepo.add(sale);
                this.$emit('close');
                if (data?.id) {
                    this.$router.push({
                        name: 'saledetail',
                        params: { id: data.id.toString(), saleName: data.name },
                    });
                }
            }
        } catch (error) {
            //401, 500 - interceptory
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    subscribersChanged(): void {
        if (this.subscriberSelectState === false) {
            this.subscriberSelectState = true;
        }
    }

    saleNameChanged(): void {
        if (this.saleNameState === false) {
            this.saleNameState = true;
        }
    }

    chargeChanged(dd: string): void {
        if (dd) {
            const ca = Number.parseFloat(dd.toString().replaceAll(',', '.'));
            if (isNaN(ca)) {
                this.chargeState = false;
            } else {
                this.sale.chargeAmount = ca;
                this.chargeState = true;
            }
        } else {
            this.chargeState = null;
        }
    }

    get convertWareHousesToSelectOptions(): any[] {
        return this.wareHouses.map((w: IWarehouse): any => {
            return { ...w, searchName: `${w?.importer?.name}: ${w?.name}` };
        });
    }

    get convertSubscriberToSelectOptions(): any[] {
        return this.subscribers.map((s: ISubscriber): any => {
            return { ...s, searchName: s?.name };
        });
    }

    convertStatesToSelectOptions(data: SaleState): { text: string; value: string }[] {
        return Object.keys(data).map((st: any): { text: string; value: string } => {
            return { text: data[st], value: st };
        });
    }

    async getInitData(): Promise<void> {
        try {
            this.isLoading = true;
            console.log('getting all');
            await Promise.all([this.getWareHouses(), this.getSubscribers(), this.getSuppliers()]);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async getSuppliers(): Promise<void> {
        const res: AxiosResponse<ISupplier[]> = await supplierRepo.getAll();
        this.suppliers = res.data;
    }

    async getSubscribers(): Promise<void> {
        try {
            const res: AxiosResponse<ISubscriber[]> = await subscriberRepo.getAll();
            const data: ISubscriber[] = res.data;
            this.subscribers = data;
        } catch (error) {}
    }

    async getWareHouses(): Promise<void> {
        try {
            const data: IWarehouse[] = await warehouses.getAll();
            this.wareHouses = data;
            console.log(this.wareHouses);
        } catch (error) {}
    }
}
