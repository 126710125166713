import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

const resource = 'crm/sale';

import { ISale, ISaleItem } from '@/models/sale.models';
import { IFile } from '@/models/gallery.models';

export default class SaleRepository {
    public static add = async (sale: ISale): Promise<ISale> => {
        try {
            const response = await Repository.post(`${resource}`, sale);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getAll = async (): Promise<ISale[]> => {
        try {
            const response: AxiosResponse<ISale[]> = await Repository.get(`${resource}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getById = async (id: number): Promise<ISale> => {
        try {
            const response: AxiosResponse<ISale> = await Repository.get(`${resource}/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static createFolder = async (id: number): Promise<ISale> => {
        try {
            const response: AxiosResponse<ISale> = await Repository.post(`${resource}/${id}/folder`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static updatePromoPrices = async (id: number, items: ISaleItem[]): Promise<AxiosResponse<ISale>> => {
        try {
            const response = await Repository.put(`${resource}/${id}/promoprices`, items);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getLastFiles = async (): Promise<IFile[]> => {
        try {
            const response: AxiosResponse<IFile[]> = await Repository.get(`${resource}/lastfiles`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static addItem = async (id: number, saleItem: ISaleItem): Promise<void> => {
        try {
            const response = await Repository.post(`${resource}/${id}/item`, saleItem);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static updateItems = async (id: number, items: ISaleItem[]): Promise<void> => {
        try {
            const response = await Repository.put(`${resource}/${id}/items`, items);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static removeItem = async (id: number, item_id: number): Promise<void> => {
        try {
            const response = await Repository.delete(`${resource}/${id}/item/${item_id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getSalesForProduct = async (product_id: number, dateFrom: moment.Moment): Promise<any[]> => {
        try {
            const response = await Repository.get(`${resource}/product/${product_id}`, {
                params: { from: dateFrom.format('DD.MM.YYYY') },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getSalesForSubscriber = async (subscriber_id: number, dateFrom: moment.Moment): Promise<ISale[]> => {
        try {
            const response = await Repository.get(`${resource}/subscriber/${subscriber_id}`, {
                params: { from: dateFrom.format('DD.MM.YYYY') },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getProductsByBod = async (billofdelivery_id: number): Promise<AxiosResponse<ISale>> => {
        try {
            const response = await Repository.get(`${resource}/productsbybod`, { params: { billofdelivery_id } });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static notifySCM = async (id: number): Promise<void> => {
        try {
            const response = await Repository.post(`${resource}/${id}/notifyready`, {});
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static addFiles = async (id: number, files: FormData): Promise<void> => {
        try {
            const response = await Repository.post(`${resource}/${id}/file`, files);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}

