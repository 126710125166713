import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';

const resource = 'crm/product';

import { IProduct, IProductExtra, IProductStockTimeline } from '@/models/product.models';
import { ISubscribersProducts } from '@/models/subscriber.models';
import moment from 'moment';

export default class ProductRepository {
    public static getAll = async (mode: string, params: object): Promise<AxiosResponse<IProduct[]>> => {
        try {
            if (!mode) mode = 'all';
            return Repository.get(`${resource}`, {
                params: {
                    mode,
                    ...params,
                },
            });
        } catch (error) {
            throw error;
        }
    };
    public static get = async (id: number): Promise<AxiosResponse<IProduct>> => {
        try {
            return Repository.get(`${resource}/${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static getStockTimeline = async (id: number): Promise<AxiosResponse<IProductStockTimeline[]>> => {
        try {
            return Repository.get(`${resource}/${id}/stocktimeline`);
        } catch (error) {
            throw error;
        }
    };
    public static getProductsBySupplier = async (idSupplier: string): Promise<AxiosResponse<IProduct[]>> => {
        try {
            return Repository.get(`${resource}/supplier?id=${idSupplier}`);
        } catch (error) {
            throw error;
        }
    };
    public static create = async (product: IProduct): Promise<AxiosResponse<IProduct>> => {
        try {
            return Repository.post(`${resource}`, JSON.stringify(product));
        } catch (error) {
            throw error;
        }
    };
    public static update = async (product: IProduct): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.put(`${resource}/${product.id}`, JSON.stringify(product));
        } catch (error) {
            throw error;
        }
    };
    public static delete = async (id: number): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.delete(`${resource}/${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static getListing = async (id: number): Promise<AxiosResponse<ISubscribersProducts[]>> => {
        try {
            return Repository.get(`${resource}/${id}/listing`);
        } catch (error) {
            throw error;
        }
    };
    public static getProductByAbraId = async (id: string): Promise<AxiosResponse<{ id: number }>> => {
        try {
            return Repository.get(`${resource}/abra/?id=${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static getExtra = async (id: number): Promise<IProductExtra> => {
        try {
            const response: AxiosResponse<IProductExtra> = await Repository.get(`${resource}/${id}/extra`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static updateExtra = async (id: number, extra: IProductExtra): Promise<IProductExtra> => {
        try {
            const response: AxiosResponse<IProductExtra> = await Repository.put(
                `${resource}/${id}/extra`,
                JSON.stringify(extra)
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static simulate = async (id: number, weeks?: number, date?: moment.Moment): Promise<any> => {
        try {
            const params: any = {};
            if (weeks) {
                params.weeks = weeks;
            }
            if (date) {
                params.date = date.format('DD.MM.YYYY');
            }
            const response: AxiosResponse<any> = await Repository.get(`${resource}/${id}/simulate`, {
                params,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
