





































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { ISubscribersProducts } from '@/models/subscriber.models';
import CTableHeader from '../table/SilTableHeader.vue';
import CTableFooter from '../table/SilTableFooter.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import { formatDateTime } from '@/utils/dateUtils';
import moment from 'moment';
import { SilModalsMixin } from '@/mixins/modals';
import { customParseFloat, customParseInt } from '@/utils/number.utils';

const productRepo = RepositoryFactory.get('product');
const subscriberRepo = RepositoryFactory.get('subscriber');

@Component({
    name: 'product-listing-tab',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class ProductListingTab extends Mixins(SilModalsMixin) {
    @Prop(Number) readonly product_id?: number;

    listing: ISubscribersProducts[] = [];
    listingChanged = false;

    // TABLE
    isLoading = false;
    perPageCount = 20;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;
    currentPage = 1;
    filter = '';
    fields: BvTableFieldArray = [
        {
            key: 'subscriber.name',
            sortable: true,
            label: 'Odběratel',
        },
        {
            key: 'listingShelfLife',
            sortable: true,
            label: 'Garant. DMT',
            formatter: (value, key, item: any) => {
                return item.itemShelfLife;
            },
            filterByFormatted: true,
            sortByFormatted: true,
        },
        {
            key: 'product.itemShelfLife',
            sortable: true,
            label: 'Celková DMT',
        },
        {
            key: 'listing.createdAt',
            sortable: true,
            label: 'Zalistováno',
            filterByFormatted: true,
            formatter: (value: any, key: any, item: ISubscribersProducts) => {
                if (item.createdAt) {
                    return formatDateTime(item.createdAt);
                }
                return undefined;
            },
            sortByFormatted: (value: any, key: any, item: ISubscribersProducts) => {
                if (item.createdAt) {
                    return moment(item.createdAt).toDate().getTime();
                }
                return undefined;
            },
        },
        // {
        //     key: 'controls',
        //     sortable: false,
        //     label: '',
        // },
    ];

    created() {
        this.getListing();
    }

    private async getListing() {
        try {
            this.isLoading = true;
            const response: AxiosResponse<ISubscribersProducts[]> = await productRepo.getListing(this.product_id);
            this.listing = response.data;
            this.listingChanged = false;
            this.totalRowsCount = response.data.length;
            this.totalUnfilteredRowsCount = response.data.length;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private async refreshListing() {
        if (this.listingChanged) {
            const shouldRefresh: boolean = await this.$confirm(
                `Přenačtením tabulky příjdete o Vaše změny. Chcete pokračovat?`
            );
            if (!shouldRefresh) return;
        }
        this.getListing();
    }

    private async saveListingChanges() {
        if (!this.product_id) return;
        const changedListing = this.listing.filter((p: ISubscribersProducts) => p.changed);
        if (!changedListing?.length) {
            this.listingChanged = false;
            return;
        }
        try {
            for (let i = 0; i < changedListing.length; i++) {
                const item: ISubscribersProducts = changedListing[i];
                const shelfLife = customParseInt(item.itemShelfLife);
                const price = customParseFloat(item.price);
                try {
                    await subscriberRepo.updateListing(item.subscriber_id, {
                        subscriber_id: item.subscriber_id,
                        product_id: item.product_id,
                        itemShelfLife: shelfLife,
                        price: price,
                    } as ISubscribersProducts);
                } catch (e) {}
            }
            await this.getListing();
            this.listingChanged = false;
            this.$toasted.success('Změny uloženy.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 5000,
            });
        } catch (e) {}
    }

    private markChanged(item: ISubscribersProducts) {
        this.listingChanged = true;
        item.changed = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private focusNext(event: unknown) {
        //
    }

    private async removeListing(item: ISubscribersProducts) {
        if (item.subscriber_id && item.product_id && item.product_id == this.product_id) {
            const shouldDelete: boolean = await this.$delete_confirm(`Opravdu chcete odstranit listing?`);
            if (!shouldDelete) return;
            try {
                await subscriberRepo.deleteListing(item.subscriber_id, {
                    subscriber_id: item.subscriber_id,
                    product_id: item.product_id,
                } as ISubscribersProducts);
                this.getListing();
            } catch (e) {}
        }
    }

    private getRouteToSubscriber(item: ISubscribersProducts) {
        return {
            name: 'subscriberdetail',
            params: {
                id: item.subscriber_id,
                subscriberName: item.subscriber?.name,
                from: this.$route.name ?? '',
            },
        };
    }
}
