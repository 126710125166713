import { AxiosError, AxiosResponse } from "axios";
import Vue from 'vue';
import i18n from '@/i18n/it18n';

export class ErrorController {
    static showErrorToast(error: AxiosResponse<any>) {
        const code: string = (error.data?.code ?? error.data?.msg) ?? 'GENERIC_ERROR'
        Vue.toasted.error(i18n.tc(`code.${code}`), {
            fullWidth: true,
            action: [],
            closeOnSwipe: true,
            fitToScreen: true,
            duration: 5000,
        });
    }

    static showTextErrorToast(text?: string) {
        Vue.toasted.error(text ?? '', {
            fullWidth: true,
            action: [],
            closeOnSwipe: true,
            fitToScreen: true,
            duration: 5000,
        });
    }
}

/*config: {url: "supplier", method: "post", data: "{"tplCode":"168","contact_id":5}", headers: {…}, transformRequest: Array(1), …}
data: {msg: "SUPPLIER_EXISTS"}
headers: {content-length: "25", content-type: "application/json; charset=utf-8"}
request: XMLHttpRequest {readyState: 4, timeout: 0, withCredentials: true, upload: XMLHttpRequestUpload, onreadystatechange: ƒ, …}
status: 409
statusText: "Conflict" */