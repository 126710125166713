import Repository from '@/api/Repository';

import service from '@/api/api.services';
import { INotification } from '@/models/notification.models';

const resource = 'crm/notification';

export default class NotificationRepository {
    public static list = async (): Promise<INotification[]> => {
        try {
            const response = await Repository.get(`${resource}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
