import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';
import { ITag } from '@/models/gallery.models';

const resource = 'tag';

export default class TagRepository {
    public static getAll = async (type?: string): Promise<ITag[]> => {
        try {
            const params: any = {};
            if (type) {
                params.type = type;
            }
            const response: AxiosResponse<ITag[]> = await Repository.get(`${resource}`, { params });
            return response.data;
        } catch (error) {
            throw Promise.reject(error);
        }
    };
}
