
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { IGetUsersResponse } from '../../models/users.models';
import { formatDate } from '@/utils/dateUtils';
import { IGetProductWithState } from '@/models/stock.models';
import TextUtils from '@/utils/textUtils';

const stock = repository.get('stock');

@Component
export default class ProductsLite extends Vue {
    isBusy = true;
    filter = '';
    supplierFilter = '';
    totalRows = 0;
    totalUnfilteredRows = 0;
    currentPage = 1;
    perPage = 50;
    zerosHidden = true;
    outboundsIncluded = true;
    unfilteredItems: IGetProductWithState[] = [];
    items: IGetProductWithState[] = [];
    perPageOptions: number[] = [10, 20, 30, 50, 100];
    currentDetailItem: any = {};
    openModal = false;

    openOrdersModal = false;
    currentOrdersDetailItem: any = {};

    fields: any[] = [
        {
            key: 'product.tplCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'productName',
            sortable: true,
            label: 'Název',
            sortByFormatted: (value: any, key: any, item: any) => {
                return item.product?.name ?? '';
            },
        },
        {
            key: 'supplierName',
            sortable: true,
            label: 'Dodavatel',
            formatter: (value: any, key: any, item: any) => {
                return item.product?.supplier?.contact?.name ?? '';
            },
            sortByFormatted: true,
            filterByFormatted: true,
        },
        {
            key: 'itemEAN',
            sortable: true,
            label: 'EAN',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: any) => {
                if (item.product.storecards.length) {
                    return item.product.storecards[0].ean;
                }
                return 0;
            },
        },
        {
            key: 'itemsPerCarton',
            sortable: true,
            label: 'KS/KRT',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: any) => {
                if (item.product.storecards.length) {
                    return item.product.storecards[0].itemsPerCarton;
                }
                return 0;
            },
        },
        {
            key: 'cartonsPerPallete',
            sortable: true,
            label: 'KRT/PAL',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: any) => {
                if (item.product.storecards.length) {
                    return item.product.storecards[0].cartonsPerPallete;
                }
                return 0;
            },
        },
        {
            key: 'itemsPerPallete',
            sortable: true,
            label: 'KS/PAL',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, key: any, item: any) => {
                if (item.product.storecards.length) {
                    return item.product.storecards[0].itemsPerPallete;
                }
                return 0;
            },
        },
        {
            key: 'product.itemShelfLife',
            sortable: true,
            label: 'Celková trvanlivost',
        },
    ];

    public filterFunc(items: IGetUsersResponse[]) {
        this.totalRows = items.length;
    }
    public async getStockState(): Promise<void> {
        this.isBusy = true;
        try {
            const data: IGetProductWithState[] = await stock.getStockState();
            this.unfilteredItems = data;
            this.items = data.filter((x) => this.customFilterFunction(x, this.supplierFilter));
            this.totalRows = this.items.length;
            this.totalUnfilteredRows = data.length;
        } catch (error) {
            console.log(error);
        }
        this.isBusy = false;
    }
    async created() {
        this.isBusy = true;
        await this.getStockState();
        this.isBusy = false;
    }
    customFilterFunction(item: any, supplierFilter: string) {
        if (
            supplierFilter &&
            supplierFilter.length > 0 &&
            (TextUtils.normalize(item?.product?.supplier?.contact?.name).indexOf(TextUtils.normalize(supplierFilter)) <
                0 ||
                !item?.product?.supplier)
        ) {
            return false;
        }
        return true;
    }
    supplierChange(val: any) {
        this.items = this.unfilteredItems.filter((x) => this.customFilterFunction(x, val));
        this.totalRows = this.items.length;
    }
    formatDate(date: Date | string | number) {
        return formatDate(date);
    }
    getRouteToProduct(item: any) {
        return {
            name: 'productdetail',
            params: {
                id: item.product?.id?.toString() ?? '#',
                productName: item.product?.name,
                from: this.$route.name ?? '',
            },
        };
    }
    getRouteToSupplier(item: any) {
        return {
            name: 'supplierdetail',
            params: {
                id: item.product?.supplier?.id?.toString() ?? '#',
                productName: item.product?.supplier?.contact?.name,
                from: this.$route.name ?? '',
            },
        };
    }
    private closeModal() {
        this.openModal = false;
    }

    private showModal(row: any) {
        this.openModal = true;
        this.currentDetailItem = row.item;
    }
    private async copyToClipboard(data: string) {
        if (!data) return;
        try {
            await navigator.clipboard.writeText(data);
            this.$toasted.success('Zkopírováno do schránky', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 2000,
            });
        } catch (err) {
            this.$toasted.error('Nepodařilo se zkopírovat do schránky. Stále je možnost nakopírovat ručně.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 2000,
            });
        }
    }
}
