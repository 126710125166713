
import { Component, Vue, Mixins } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { formatDate } from '@/utils/dateUtils';
import { ErrorController } from '@/utils/error.controller';
import { SilModalsMixin } from '@/mixins/modals';
import { IBillOfDelivery, IBillOfDeliveryItem, IOutboundRow } from '@/models/billsofdelivery.models';
import { IReceivedOrder } from '@/models/receivedorder.models';
import moment from 'moment';
import { BAD_CODES } from '@/utils/scm.utils';
import { IFile, IFolder } from '@/models/gallery.models';
import { BvTableFieldArray } from 'bootstrap-vue';
import MathUtils from '@/utils/math.utils';
import ApiService from '@/api/api.services';
import SaleDetailModal from '@/components/sale/SaleDetailModal.vue';

const billsOfDelivery = repository.get('billsofdelivery');
const receivedOrder = repository.get('receivedorder');
const saleRepo = repository.get('sale');
const fileRepo = repository.get('file');

interface IOutBoundKRTHelper {
    krts: number[];
}
// {

// }
@Component({
    components: {
        'sale-detail-modal': SaleDetailModal,
    },
})
export default class BillsOfDeliveryDetail extends Mixins(SilModalsMixin) {
    private billOfDelivery: IBillOfDelivery | null = null;
    private isLoading = false;

    // Promo detail
    openDetailModal = false;
    saleDetailID = null;

    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    isBusyABRA = false;
    currentPageABRA = 1;
    perPageABRA = 50;
    items?: IBillOfDeliveryItem[] = [];
    itemsABRA?: IBillOfDeliveryItem[] = [];
    outboundRows?: any[] = [];
    saleItems: any[] = [];
    orderMap: any = {};
    info: any = {};
    filter = '';
    filterABRA = '';
    orderByColumn = 'productCode';
    orderByColumnABRA = 'storecardCode';
    folders: IFolder[] = [];
    outboundHelper: any = {};
    baseUrl = '';
    fields: BvTableFieldArray = [
        {
            key: 'productCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'productName',
            sortable: true,
            label: 'Název',
        },
        {
            key: 'batch',
            sortable: true,
            label: 'Šarže',
        },
        {
            key: 'expiry',
            sortable: true,
            label: 'DMT',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: string) => {
                return formatDate(value);
            },
        },
        {
            key: 'dd',
            sortable: true,
            label: 'KS',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: number, dd, item) => {
                return this.getItemAmountInKS(item);
            },
        },
        {
            key: 'amount',
            sortable: true,
            label: 'KRT',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: number, dd, item: IOutboundRow) => this.getOutboundAmountKRT(item),
        },
        {
            key: 'quantityPalletes',
            sortable: true,
            label: 'PAL',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: number, dd, item) => {
                let ratio = 0;
                switch (item.unitCode) {
                    case 'krt':
                        ratio = item.origin?.quantityCartons / item.origin?.quantityPalletes;
                        return Math.round((item.amount / ratio) * 100) / 100;
                    case 'ks':
                        ratio = item.origin?.quantity / item.origin?.quantityPalletes;
                        return Math.round((item.amount / ratio) * 100) / 100;
                    case 'bal':
                        ratio = item.origin?.quantity / item.origin?.quantityPalletes;
                        if (BAD_CODES.includes(item.productCode)) {
                            return Math.round(((item.amount * 10) / ratio) * 100) / 100;
                        }
                        return Math.round((item.amount / ratio) * 100) / 100;
                    case 'pal':
                        return item.amount;
                }
            },
        },
        {
            key: 'trv',
            sortable: true,
            label: 'Zbývá DMT',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: string, dd, item) => {
                const now = moment();
                const dt = moment(item.expiry);
                return dt.diff(now, 'days');
            },
        },
        {
            key: 'ordered',
            sortable: true,
            label: 'Objednáno',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: undefined, dd, item) => {
                return item.origin?.quantity ?? 0;
            },
        },
        {
            key: 'serviceLevel',
            sortable: true,
            label: 'Service level',
            formatter: (value: undefined, dd, item) => {
                const outs = item.origin?.outbounds;
                if (outs?.length) {
                    const ordered = item.origin?.quantity ?? 0;
                    let delivered = 0;
                    for (let i = 0; i < outs.length; i++) {
                        delivered += this.getItemAmountInKS(outs[i]);
                    }
                    return `${Math.round((delivered / ordered) * 10000) / 100} %`;
                }
                return '';
            },
        },
    ];

    fieldsABRA: BvTableFieldArray = [
        {
            key: 'storecardCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'storecardName',
            sortable: true,
            label: 'Název',
        },
        {
            key: 'quantity',
            sortable: true,
            label: 'KS',
        },
        {
            key: 'quantityCartons',
            sortable: true,
            label: 'KRT',
        },
        {
            key: 'quantityPalletes',
            sortable: true,
            label: 'PAL',
            formatter: (value: number) => {
                if (value != null) return Math.round(value * 100) / 100;
                return undefined;
            },
        },
        {
            key: 'serviceLevel',
            sortable: true,
            label: 'Service level',
            formatter: (value: number) => {
                return `${value} %`;
            },
        },
        {
            key: 'cartonDifference',
            sortable: true,
            sortByFormatted: true,
            label: 'Rozdíl (KRT)',
            formatter: (value: number) => {
                return `${value ? value : ''}`;
            },
        },
    ];

    private getTotalServiceLevel() {
        return this.info.serviceLevel;
    }

    private getOutboundAmountKRT(item: IOutboundRow) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                val = item.amount;
                break;
            case 'ks':
                ratio = item.origin?.quantity / item.origin?.quantityCartons;
                val = Math.round((item.amount / ratio) * 100) / 100;
                break;
            case 'bal':
                ratio = item.origin?.quantity / item.origin?.quantityCartons;
                if (BAD_CODES.includes(item.productCode)) {
                    val = Math.round(((item.amount * 10) / ratio) * 100) / 100;
                } else {
                    val = Math.round((item.amount / ratio) * 100) / 100;
                }
                break;
            case 'pal':
                ratio = item.origin?.quantityCartons / item.origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private getOutboundAmountPAL(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityPalletes;
                if (BAD_CODES.includes(item.productCode)) {
                    val = (item.amount * 10) / ratio;
                } else {
                    val = item.amount / ratio;
                }
                break;
            case 'pal':
                val = item.amount;
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    get files() {
        const fileArrays = this.folders.map((folder) => folder.files);
        return Array.prototype.concat.apply([], fileArrays);
    }

    private getItemAmountInKS(item: any) {
        let ratio = 0;
        switch (item.unitCode) {
            case 'krt':
                ratio = item.origin?.quantity / item.origin?.quantityCartons;
                return Math.round(item.amount * ratio * 100) / 100;
            case 'ks':
                return item.amount;
            case 'bal':
                if (BAD_CODES.includes(item.productCode)) {
                    return item.amount * 10;
                }
                return item.amount;
            case 'pal':
                ratio = item.origin?.quantity / item.origin?.quantityPalletes;
                return Math.round(item.amount * ratio * 100) / 100;
        }
        return 0;
    }

    private getItemServiceLevelHelper(item: any): { delivered: number; ordered: number } {
        const outs = item.outbounds;
        let ordered = 0;
        let unit = 'KRT';
        if (item.quantityCartons) {
            ordered = item.quantityCartons;
        } else if (item.quantityPalletes) {
            unit = 'PAL';
            ordered = item.quantityPalletes;
        }
        let delivered = 0;
        for (let i = 0; i < outs.length; i++) {
            if (unit === 'KRT') {
                delivered += this.getOutboundAmountKRT(outs[i]);
            } else if (unit === 'PAL') {
                delivered += this.getOutboundAmountPAL(outs[i], outs[i].origin);
            }
        }
        return {
            ordered,
            delivered,
        };
    }

    private goBack() {
        this.$router.back();
    }

    private formatDate(date: string) {
        return date ? formatDate(date) : '';
    }

    private async getBillOfDelivery() {
        try {
            const idBillOfDelivery: string = this.$route.params.id;
            const res: AxiosResponse<IBillOfDelivery> = await billsOfDelivery.get(idBillOfDelivery);
            const data = res.data;
            this.billOfDelivery = data;
            const productsOnSale = await saleRepo.getProductsByBod(idBillOfDelivery);

            const outboundRows = data.outbounds?.map((x) => x.rows)?.flat() ?? [];
            const items = data.items ?? [];

            this.info = this.fillInItems(items, outboundRows, productsOnSale);

            if (this.info.serviceLevel > 0 && this.info.serviceLevel < 100) {
                items.forEach((i) => {
                    const item = i as any;
                    if (item.extra?.dateOfAvailability) {
                        item.extra.dateOfAvailability = moment(item.extra.dateOfAvailability).toDate();
                    }
                });
            }

            this.saleItems = productsOnSale;
            this.items = outboundRows;
            this.itemsABRA = items;
            if (this.billOfDelivery.providers?.length) {
                for (let i = 0; i < this.billOfDelivery.providers.length; i++) {
                    const recOrder: AxiosResponse<IReceivedOrder> = await receivedOrder.getByAbraId(
                        this.billOfDelivery.importer_id,
                        this.billOfDelivery.providers[i]
                    );
                    Vue.set(this.orderMap, this.billOfDelivery.providers[i], recOrder.data);
                }
            }
            if (this.billOfDelivery.outbounds?.length) {
                for (let i = 0; i < this.billOfDelivery.outbounds.length; i++) {
                    const ob = this.billOfDelivery.outbounds[i];
                    if (ob.folder_id) {
                        try {
                            const folder: IFolder = await fileRepo.getFolder(ob.folder_id);
                            this.folders.push(folder);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    private fillInItems(abraItems: any[], tplItems: any[], saleItems: any[]) {
        const totalSLHelper: { delivered: number; ordered: number } = { delivered: 0, ordered: 0 };
        for (let i = 0; i < abraItems.length; i++) {
            const _item = abraItems[i];
            const _tplItems = tplItems.filter((x) => x.productCode === _item.storecardCode);
            _item.outbounds = _tplItems;
            if (saleItems?.length) {
                const sale = saleItems.filter((x) => x.tplCode === _item.storecardCode);
                _item.sale = sale;
                if (sale?.length > 0) {
                    _item._rowVariant = 'success';
                }
            }
        }
        for (let i = 0; i < tplItems.length; i++) {
            const _item = tplItems[i];
            const _abraItem = abraItems.find((x) => x.storecardCode === _item.productCode);
            if (_abraItem) {
                _item.origin = _abraItem;
                if (_abraItem._rowVariant) {
                    _item._rowVariant = _abraItem._rowVariant;
                }
            }
        }
        for (let i = 0; i < abraItems.length; i++) {
            const slHelper = this.getItemServiceLevelHelper(abraItems[i]);
            abraItems[i].serviceLevel = MathUtils.round((slHelper.delivered / slHelper.ordered) * 100, 2);
            abraItems[i].cartonDifference = slHelper.delivered - slHelper.ordered;
            totalSLHelper.delivered += slHelper.delivered;
            totalSLHelper.ordered += slHelper.ordered;
        }
        const sl = Math.round((totalSLHelper.delivered / totalSLHelper.ordered) * 10000) / 100;
        return {
            serviceLevel: sl,
        };
    }

    private get providerDisplayNames() {
        if (!this.billOfDelivery?.providers?.length) return '';
        const result: string[] = [];
        for (let i = 0; i < this.billOfDelivery.providers.length; i++) {
            const provider = this.billOfDelivery.providers[i];
            const providerData: IReceivedOrder = this.orderMap[provider];
            if (providerData) {
                result.push(providerData.orderDisplayName);
            } else {
                result.push(provider);
            }
        }
        return result.join(', ');
    }

    private get getWarehouseTPLCode() {
        if (this.billOfDelivery != null && this.billOfDelivery.contact?.warehouses?.length) {
            return this.billOfDelivery.contact.warehouses[0].tplCode;
        }
        return '';
    }

    private get getWarehouseInfo() {
        if (this.billOfDelivery != null && this.billOfDelivery.contact?.warehouses?.length) {
            const warehouse = this.billOfDelivery.contact.warehouses[0];
            return `${warehouse.name} (TPL ID: ${warehouse.tplCode})`;
        }
        return '';
    }

    private get extCustomerOrderNumber() {
        return this.billOfDelivery?.outbounds?.map((x) => x.customerOrderNumber)?.join(', ');
    }

    private get deliveryDate() {
        if (!this.billOfDelivery?.documentDate) return undefined;
        return formatDate(this.billOfDelivery?.documentDate);
    }

    private get createdDate() {
        if (!this.billOfDelivery?.createdDate) return undefined;
        return formatDate(this.billOfDelivery?.createdDate);
    }

    private get billOfDeliverySales() {
        const result: any[] = [];
        const map = new Map();
        for (let i = 0; i < this.saleItems.length; i++) {
            if (this.itemsABRA?.length) {
                if (this.itemsABRA.find((x) => x.storecardCode === this.saleItems[i].tplCode)) {
                    if (!map.has(this.saleItems[i].sale_id)) {
                        map.set(this.saleItems[i].sale_id, true);
                        result.push(this.saleItems[i]);
                    }
                }
            }
        }
        return result;
    }

    private getRouteToProductDetailSC(item: any) {
        return {
            name: 'productdetail',
            params: {
                id: item.storecard_idAbra,
                productName: item.storecardName,
                abra: true,
            },
        };
    }

    private getRouteToProductDetail(item: any) {
        return {
            name: 'productdetail',
            params: {
                id: item.product?.id,
                productName: item.product?.name,
                from: this.$route.name ?? '',
            },
        };
    }

    private get billOfDeliveryStatus() {
        if (this.info) {
            if (this.info.serviceLevel === 0) {
                return 'Probíhá expedice';
            } else if (this.info.serviceLevel === 100) {
                return 'Vyskladněno';
            } else {
                const sLevel = Math.round(this.info.serviceLevel * 100) / 100;
                return `Vyskladněno s rozdílem (${sLevel} %)`;
            }
        }
        return 'Neznámý';
    }

    async created() {
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.getBillOfDelivery();
    }

    private getFileIcon(mime: string) {
        const _c = 'fa';
        if (mime) {
            if (mime.indexOf('image') >= 0) {
                return `${_c} fa-file-image`;
            }
            if (mime.indexOf('pdf') >= 0) {
                return `${_c} fa-file-pdf`;
            }
        }
        return `${_c} fa-file`;
    }

    private shorterFileName(fileName: string) {
        let tmp = fileName.substring(0, 32);
        if (fileName.length > 32) {
            tmp += '...';
        }
        return tmp;
    }

    private async downloadFile(file: IFile, preview?: boolean) {
        if (!file.id) return;
        try {
            const res = await fileRepo.download(file.id);
            const blob = new Blob([res.data], { type: file.mimetype });
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.target = '_blank';
            if (!preview || (file.mimetype.indexOf('pdf') < 0 && file.mimetype.indexOf('image/') < 0)) {
                a.download = file.name;
            }
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        } catch (e) {
            console.error('Could not download file', e);
            ErrorController.showErrorToast(e as unknown as any);
        }
    }

    private get apologyPDFLink() {
        if (!this.billOfDelivery) return '#';
        return `${this.baseUrl}/billofdelivery/${
            this.billOfDelivery.id
        }/diffpdf?auth=${ApiService.getAuthToken()}&token=${ApiService.getToken()}`;
    }

    private hasDifference(item: any) {
        return item.serviceLevel != null && item.serviceLevel < 100;
    }

    isItemExtrasEditing = false;
    itemExtrasEdited: IBillOfDeliveryItem | null = null;
    private showModal(item: IBillOfDeliveryItem) {
        this.itemExtrasEdited = item;
        this.isItemExtrasEditing = true;
    }
    private onModalClosed() {
        this.itemExtrasEdited = null;
        this.isItemExtrasEditing = false;
    }
    private get contactName() {
        const item = this.billOfDelivery;
        if (!item) return '';
        if (!item.contact?.warehouses?.length) return item.contactName;
        const warehouse = item.contact.warehouses[0];
        if (!warehouse?.subscriber) return item.contactName;
        return `${warehouse.subscriber.name}`;
    }

    private get palletsToDeliver() {
        const palleteCount = this.billOfDelivery?.items?.reduce(
            (acc: number, item: any) => acc + item.quantityPalletes,
            0
        );
        if (!palleteCount) return 0;
        return Math.round(palleteCount * 100) / 100;
    }

    showOnlyShortened = false;
    private get itemsABRAGetter() {
        if (this.showOnlyShortened === true) {
            return this.itemsABRA?.filter((ia) => (ia as any).serviceLevel < 100);
        }
        return this.itemsABRA;
    }
    private showDetailModal(row: any) {
        if (!row) return;
        this.saleDetailID = row.sale_id;
        this.openDetailModal = true;
    }
    private closeDetailModal() {
        this.openDetailModal = false;
        this.saleDetailID = null;
    }
}
