
import { Component, Mixins, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { IProduct } from '@/models/product.models';
import { ISupplier } from '@/models/supplier.models';
import { ISale, ISaleItem, ISaleItemDetail } from '@/models/sale.models';
import moment from 'moment';
import { formatDate as ff } from '@/utils/dateUtils';
import AddSaleItem from '@/components/sale/AddSaleItem.vue';
import { SaleState } from '@/components/sale/NewSale.vue';
import { SilModalsMixin } from '@/mixins/modals';
import { ErrorController } from '@/utils/error.controller';
import { IFile, ITag } from '@/models/gallery.models';
import SaleSummaryTab from '@/components/sale/SaleSummaryTab.vue';
import FileEditModal from '@/components/file/FileEditModal.vue';

const saleRepo = repository.get('sale');
const fileRepo = repository.get('file');
// const products = repository.get('product');

@Component({
    components: {
        'add-sale-item-modal': AddSaleItem,
        'sale-summary-tab': SaleSummaryTab,
        'file-edit-modal': FileEditModal,
    },
})
export default class SaleDetail extends Mixins(SilModalsMixin) {
    isLoading = false;
    addItemModalOpened = false;
    newExtUrl = null;
    lastErrorShown: moment.Moment | null = null;
    sale: ISale | null = null;
    saleStateEnum = SaleState;
    itemChanges: any = {};
    itemWarnings: any = {};
    itemsChanged = false;
    //
    editingFile: string | null = null;
    editingFileShow = false;
    //
    splitOptions: any[] = Array.prototype.concat(
        [], //[{ value: null, text: 'Jiné' }],
        [...Array(10).keys()].map((k) => ({
            value: (k + 1) / 10,
            text: `${(k + 1) * 10}%`,
        }))
    );
    itemFields: any = [
        {
            key: 'product.tplCode',
            sortable: true,
            label: 'Kód',
        },
        {
            key: 'product.name',
            sortable: true,
            label: 'Sortiment',
        },
        {
            key: 'price',
            sortable: false,
            label: 'ANC',
            thClass: 'column-100',
        },
        {
            key: 'promoPrice',
            sortable: false,
            label: 'Prodejní cena',
            thClass: 'column-100',
        },
        {
            key: 'ean',
            sortable: true,
            label: 'EAN',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, gg: any, item: any) => {
                if (item.product?.storecards?.length) {
                    const primary = item.product.storecards.reduce(
                        (acc: any, it: any) => (it.link?.primary ?? false ? it : acc),
                        null
                    );
                    if (primary) {
                        return primary.ean;
                    }
                }
                return '';
            },
        },
        {
            key: 'kspal',
            sortable: true,
            label: 'KS/PAL',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: any, gg: any, item: any) => {
                if (item.product?.storecards?.length) {
                    const primary = item.product.storecards.reduce(
                        (acc: any, it: any) => (it.link?.primary ?? false ? it : acc),
                        null
                    );
                    if (primary) {
                        return primary.itemsPerPallete;
                    }
                }
                return '';
            },
        },
        {
            key: 'totalfrc',
            sortable: false,
            label: 'Celk. FRC',
            thClass: 'column-150',
        },
    ];
    summaryFields: any[] = [
        {
            key: 'code',
            label: 'Kód',
        },
        {
            key: 'name',
            label: 'Název',
        },
        {
            key: 'totalForecast',
            label: 'Celkový FRC (KS)',
        },
        {
            key: 'delivered',
            label: 'Dodáno (KS)',
        },
        {
            key: 'remaining',
            label: 'Zbývá (KS)',
        },
    ];
    dynamicItemFields: any[] = [];
    async getSale(): Promise<void> {
        try {
            const response: ISale = await saleRepo.getById(this.$route.params.id);
            this.sale = {
                ...response,
                items:
                    response.items?.map((i: ISaleItem) => ({
                        ...i,
                        ...this.getWeekNumbers(i),
                        ...this.getSelectedSplit(i),
                        price: i.price?.toString().replaceAll('.', ','),
                    })) ?? [],
            };
        } catch (error) {
            console.error(error);
        }
    }
    getSelectedSplit(item: ISaleItem) {
        if (!item || !item.details || !item.details.length) return { splitSelected: 1.0 };
        let min: ISaleItemDetail | null = null;
        let amount = 0;
        for (let i = 0; i < item.details.length; i++) {
            const detail = item.details[i];
            amount += detail.count;
            if (min) {
                if (min.year > detail.year || (min.year === detail.year && min.weekNumber > detail.weekNumber)) {
                    min = detail;
                }
            } else {
                min = detail;
            }
        }
        if (!min) return { splitSelected: 1.0 };
        return {
            splitSelected: Math.round((min.count / amount) * 10) / 10,
            totalfrc: amount,
            unchangedTotalFRC: amount,
        };
    }
    //   async getProducts(): Promise<void> {
    //     try {
    //       const response: AxiosResponse<IProduct[]> = await products.getAll(
    //         'listing'
    //       );
    //       this.products = response.data;
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    formatDate(date: Date) {
        return ff(date);
    }
    routeBack() {
        this.$router.push({ name: 'sales' });
    }

    getFileIcon(mime: string) {
        const _c = 'fa';
        if (mime) {
            if (mime.indexOf('image') >= 0) {
                return `${_c} fa-file-image`;
            }
            if (mime.indexOf('pdf') >= 0) {
                return `${_c} fa-file-pdf`;
            }
        }
        return `${_c} fa-file`;
    }

    shorterFileName(fileName: string) {
        let tmp = fileName.substring(0, 32);
        if (fileName.length > 32) {
            tmp += '...';
        }
        return tmp;
    }
    getFileTags(file: any) {
        if (!file || !file.tags) return;
        return file.tags.map((t: any) => t.name).join(', ');
    }
    //   routeToContact() {
    //     this.$router.push({
    //       name: 'contactdetail',
    //       params: { id: (this.supplier?.contact as any)?.id },
    //     });
    //   }
    uploadFiles() {
        document.getElementById('fileUpload')?.click();
    }
    async downloadFile(file: IFile, preview?: boolean) {
        if (!file.id) return;
        try {
            const res = await fileRepo.download(file.id);
            const blob = new Blob([res.data], { type: file.mimetype });
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.target = '_blank';
            if (!preview || (file.mimetype.indexOf('pdf') < 0 && file.mimetype.indexOf('image/') < 0)) {
                a.download = file.name;
            }
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        } catch (e) {
            console.error(e);
        }
    }
    editFile(file: IFile) {
        if (!file?.id) return;
        this.editingFile = file.id;
        this.editingFileShow = true;
    }
    closeEditingFile() {
        this.editingFileShow = false;
        this.editingFile = null;
    }
    savedEditingFile() {
        this.closeEditingFile();
        this.getSale();
    }
    async deleteFile(file: any) {
        if (!file.id) return;
        const willDelete = await this.$confirm(`Opravdu chcete odstranit soubor ${file.name}?`, {
            okVariant: 'danger',
        });
        if (willDelete) {
            try {
                const res = await fileRepo.delete(file.id);
                await this.getSale();
                this.$toasted.success('Soubor odstraněn.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
            } catch (e) {
                console.error('Could not delete file', e);
                ErrorController.showErrorToast(e as any);
            }
        }
    }

    async uploadSelectedFiles(input: any) {
        console.log(input.target.files);
        const formData: FormData = new FormData();
        input.target.files.forEach((file: File) => {
            formData.append(file.name, file);
        });
        const id: string = this.$route.params.id;
        const res: AxiosResponse<any> = await saleRepo.addFiles(id, formData);
        this.getSale();
    }
    created() {
        this.refreshData();
    }
    async refreshData() {
        await this.getSale();
        if (this.sale !== null) {
            this.dynamicItemFields = this.getWeekRange().map((w) => ({
                key: `week_${w.year}_${w.week}`,
                sortable: false,
                label: `${w.week}`,
                thClass: 'column-150',
            }));
            this.itemFields = [
                ...this.itemFields,
                ...this.dynamicItemFields,
                { key: 'actions', label: '', sortable: false },
            ];
        }
        // this.getProducts();
    }
    getDateYear(date: moment.Moment) {
        if (date.month() == 0 && date.week() > 50) {
            return date.year() - 1;
        }
        return date.year();
    }
    getWeekRange(): { year: number; week: number }[] {
        const range: { year: number; week: number }[] = [];
        if (this.sale !== null) {
            const _mS = moment(this.sale.dateANC);
            const _mF = moment(this.sale.dateFinish);
            let currWeek = _mS.week();
            let currWeekHash = Number.parseInt(`${this.getDateYear(_mS)}${_mS.week()}`);
            const finalWeek = _mF.week();
            const finalWeekHash = Number.parseInt(`${this.getDateYear(_mF)}${_mF.week()}`);
            const currYear = moment(new Date(_mS.year(), 0, 1));
            let currLeap = 0;
            let loops = 0;
            let end = false;
            while (!end) {
                // console.log({
                //   currWeek,
                //   finalWeek,
                //   currLeap,
                //   currYear: currYear.format('DD.MM.YYYY'),
                //   currWeekHash,
                //   finalWeekHash,
                // });
                // Avoid tab dead-lock in case of error.
                if (loops >= 128) {
                    end = true;
                    ErrorController.showTextErrorToast('Došlo k chybě ve zpracování FRC. Obraťte se na programátora.');
                    return [];
                    break;
                }
                // End if we hit end week
                end = currWeekHash === finalWeekHash;
                // Push week
                range.push({ week: currWeek, year: currYear.year() });
                // Check week year overflow
                currWeek++;
                if (currWeek > currYear.weeksInYear()) {
                    currWeek = 1;
                    currLeap++;
                    currYear.add(1, 'year');
                }
                currWeekHash = Number.parseInt(`${this.getDateYear(_mS) + currLeap}${currWeek}`);
                // Count loops
                loops++;
            }
        }
        return range;
    }
    getSlotName(key: string): string {
        return `cell(${key})`;
    }
    getPalCount(item: any, key: string) {
        if (item?.product?.storecards?.length) {
            const ksStr = item[key];
            if (!isNaN(ksStr)) {
                return Math.round((ksStr / item.product.storecards[0].itemsPerPallete) * 100) / 100;
            }
        }
        return 0;
    }
    getKRTCount(item: any, key: string) {
        if (item?.product?.storecards?.length) {
            const ksStr = item[key];
            if (!isNaN(ksStr)) {
                return Math.round((ksStr / item.product.storecards[0].itemsPerCarton) * 100) / 100;
            }
        }
        return 0;
    }
    getWeekNumbers(item: ISaleItem) {
        const result: any = {};
        const ds = item.details;
        if (!ds) return result;
        for (let i = 0; i < ds.length; i++) {
            result[`week_${ds[i].year}_${ds[i].weekNumber}`] = ds[i].count;
        }
        return result;
    }
    markChanged(id: number, type: string, key?: string): void {
        this.itemsChanged = true;
        if (!this.itemChanges[id]) this.itemChanges[id] = {};
        if (type === 'price') {
            this.itemChanges[id].price = true;
        } else if (type === 'weeks') {
            for (let i = 0; i < this.dynamicItemFields.length; i++) {
                const field = this.dynamicItemFields[i];
                this.itemChanges[id][field.key] = true;
            }
        } else if (type === 'week' && key) {
            this.itemChanges[id][key] = true;
        } else {
            console.error('Type not defined!');
        }
    }
    splitAmount(value: string, id: number, split: number | undefined) {
        if (!this.sale || !this.sale.items) return;
        const item: any = this.sale.items.find((x) => x.id === id);
        if (!item) return;
        const amount: number = parseInt(value);
        const dividerWeeks: number = this.dynamicItemFields.length - 1;
        const firstWeekPercent = split ?? item.splitSelected;
        let firstWeekAmount = 0;
        let remainingAmount = amount;
        for (let i = 0; i < this.dynamicItemFields.length; i++) {
            const field = this.dynamicItemFields[i];
            if (i === 0) {
                firstWeekAmount = Math.ceil(amount * firstWeekPercent);
                item[field.key] = firstWeekAmount;
            } else {
                const possibleAmount = Math.ceil((amount - firstWeekAmount) / dividerWeeks);
                if (remainingAmount >= possibleAmount) {
                    item[field.key] = possibleAmount;
                } else {
                    item[field.key] = remainingAmount;
                }
            }
            remainingAmount -= item[field.key];
        }
        if (split) {
            this.markChanged(id, 'weeks');
            this.$forceUpdate();
        }
        if (amount > item.unchangedTotalFRC) {
            this.itemWarnings[
                id
            ] = `Chcete aktualizovat celkový forecast z ${item.unchangedTotalFRC} na ${amount} u položky ${item.product?.name}?`;
            item._rowVariant = 'warning';
        } else if (amount < item.unchangedTotalFRC) {
            this.itemWarnings[
                id
            ] = `Chcete aktualizovat celkový forecast z ${item.unchangedTotalFRC} na ${amount} u položky ${item.product?.name}?`;
            item._rowVariant = 'warning';
        } else {
            delete item._rowVariant;
            delete this.itemWarnings[id];
        }
    }
    reverseSplitAmount(value: string, id: number, currKey: string) {
        if (!this.sale || !this.sale.items) return;
        const item: any = this.sale.items.find((x) => x.id === id);
        if (!item) return;
        if (!value) value = '0';
        const _v = parseInt(value);
        const weeks = this.dynamicItemFields;
        let totalfrc = 0;
        let totalWithoutCurrentKey = 0;
        for (let i = 0; i < weeks.length; i++) {
            const _w = weeks[i];
            if (currKey === _w.key) {
                totalfrc += _v;
            } else {
                const _c = item[_w.key] || '0';
                const _n = parseInt(_c);
                totalfrc += _n;
                totalWithoutCurrentKey += _n;
            }
        }
        if (totalfrc > item.unchangedTotalFRC) {
            this.itemWarnings[
                id
            ] = `Chcete aktualizovat celkový forecast z ${item.unchangedTotalFRC} na ${totalfrc} u položky ${item.product?.name}?`;
            item._rowVariant = 'warning';
        } else if (totalfrc < item.unchangedTotalFRC) {
            this.itemWarnings[
                id
            ] = `Chcete aktualizovat celkový forecast z ${item.unchangedTotalFRC} na ${totalfrc} u položky ${item.product?.name}?`;
            item._rowVariant = 'warning';
        } else {
            delete item._rowVariant;
            delete this.itemWarnings[id];
        }
        item.totalfrc = totalfrc;
    }
    async saveChanged() {
        const item_ids: number[] = Object.keys(this.itemChanges).map((id) => Number(id));
        const toUpdate: any[] = [];
        for (let i = 0; i < item_ids.length; i++) {
            const result: any = {};
            const currentItem: ISaleItem | undefined = this.sale?.items?.find((item) => item.id === item_ids[i]);
            if (currentItem) {
                result.id = item_ids[i];
                const keys = Object.keys(this.itemChanges[item_ids[i]]);
                for (let k = 0; k < keys.length; k++) {
                    const val: string = (currentItem as any)[keys[k]];
                    if (keys[k].startsWith('week')) {
                        const parsed = this.parseWeekKey(keys[k]);
                        const weekNumber: number = parsed.week;
                        const year: number = parsed.year;
                        const count: number = typeof val === 'number' ? val : parseInt(val.replaceAll(/[^\d.-]/g, ''));
                        if (!result.details) result.details = [];
                        result.details.push({
                            weekNumber,
                            count,
                            year,
                        });
                    } else {
                        result[keys[k]] = parseFloat(val.replaceAll(',', '.').replaceAll(/[^\d.-]/g, ''));
                    }
                }
                toUpdate.push(result);
            }
        }
        if (Object.keys(this.itemWarnings).length) {
            const values = Object.values(this.itemWarnings);
            const continueSaving = await this.$confirm(values.join('\n'), {
                size: 'md',
                bodyClass: 'text-pre',
                okTitle: 'Přesto uložit',
                cancelTitle: 'Zrušit',
                title: 'Upozornění',
            });
            if (!continueSaving) return;
        }
        try {
            await saleRepo.updateItems(this.sale?.id, toUpdate);
            await this.getSale();
            this.itemsChanged = false;
            this.itemChanges = {};
            this.$toasted.success('Položky upraveny.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 10000,
            });
        } catch (e) {
            console.log(e);
        }
        // TODO: Continue in updating
    }
    getProduct(id: number): IProduct | null {
        return null;
        // return this.supplier?.products?.find((x: IProduct) => x.id === id) || null;
    }
    async deleteItem(item: ISaleItem): Promise<void> {
        const allow: boolean = await this.$delete_confirm(
            `Opravdu chcete odstranit z letákové akce "${this.sale?.name ?? ''}" položku "${item?.product?.name}"?`
        );
        if (allow) {
            await saleRepo.removeItem(item.sale_id, item.id);
            await this.getSale();
            this.$toasted.success('Položka odstraněna.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 10000,
            });
        }
    }
    closeModal() {
        this.addItemModalOpened = false;
        this.getSale();
    }
    async deleteSale() {
        if (this.sale?.id) {
            const reason = prompt(`Uveďte důvod pro stornování akce "${this.sale?.name ?? ''}"`, '');
            if (reason != null) {
                await saleRepo.deleteById(this.sale.id, reason);
                this.routeBack();
            }
        }
    }
    get saleDetailsExist(): boolean {
        if (!this.sale) return false;
        if (!this.sale.items?.length) return false;
        let exists = false;
        for (let i = 0; i < this.sale.items.length; i++) {
            const item = this.sale.items[i];
            if (!item.details?.length) continue;
            exists = true;
            break;
        }
        return exists;
    }
    async notifySCM() {
        if (this.sale?.id) {
            if (this.itemsChanged) {
                this.$toasted.error('Nejprve uložte rozpracované změny.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
                return;
            }
            if (!this.saleDetailsExist) {
                this.$toasted.error('Letáková akce nelze odeslat bez položek a uloženého forecastu.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
                return;
            }
            try {
                await saleRepo.notifySCM(this.sale.id);
                this.$toasted.success('Odesláno k naplánování.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
                this.routeBack();
            } catch (e) {
                //
            }
        }
    }
    get saleStatus() {
        if (this.sale) {
            if (this.sale.status === 'ACTIVE') {
                const now = new Date();
                const dateStart = new Date(this.sale.dateANC);
                if (dateStart > now) {
                    return Object(this.saleStateEnum)[this.sale.status];
                } else {
                    const dateEnd = new Date(this.sale.dateFinish);
                    if (dateEnd >= now) {
                        return 'Aktivní';
                    } else {
                        return 'Ukončená';
                    }
                }
            }
            return Object(this.saleStateEnum)[this.sale.status];
        }
        return '';
    }
    get saleCharge() {
        if (this.sale) {
            if (this.sale.chargeAmount && this.sale.chargeCurrency) {
                return `${this.sale.chargeAmount} ${this.sale.chargeCurrency}`;
            }
        }
        return '';
    }
    get saleSupplier() {
        return this.sale?.supplier?.contact?.name ?? '';
    }
    parseWeekKey(key: string): { key: string; year: number; week: number } {
        const weekKeyRegex = /week_([0-9]+)_([0-9]+)/g;
        const parsed = weekKeyRegex.exec(key);
        if (!parsed) {
            if (this.lastErrorShown == null || this.lastErrorShown.isBefore(moment().subtract(2, 'seconds'))) {
                this.lastErrorShown = moment();
                ErrorController.showTextErrorToast(
                    'Došlo k chybě ve zpracování týdnů FRC. Obraťte se na programátora.'
                );
            }
            throw new Error();
        }
        return {
            key,
            year: parseInt(parsed[1]),
            week: parseInt(parsed[2]),
        };
    }
    isFutureWeek(weekKey: string): boolean {
        if (!weekKey) return false;
        const parsed = this.parseWeekKey(weekKey);
        const weekNumber = parsed.week;
        const year = parsed.year;
        const currentWeekNumber = moment().week();
        const currYear = this.getDateYear(moment());
        return year > currYear || (year === currYear && weekNumber > currentWeekNumber);
    }
    isANCInFutureWeek() {
        if (!this.sale) return false;
        const tmp = moment(this.sale.dateANC);
        const ancWeekNumber = tmp.week();
        const ancYearNumber = tmp.year();
        const currentWeekNumber = moment().week();
        const currentYearNumber = moment().year();
        return ancWeekNumber > currentWeekNumber && ancYearNumber >= currentYearNumber;
    }
    getTotalFRCText(item: any) {
        const totalfrc = item.totalfrc || 0;
        const unchangedfrc = item.unchangedTotalFRC || 0;
        const splitSelected = item.splitSelected * 100;
        if (!item.unchangedTotalFRC || totalfrc === unchangedfrc) {
            return `${totalfrc} (${splitSelected}%)`;
        }
        return `${totalfrc}/${unchangedfrc} (${splitSelected}%)`;
    }
    get remainingDays() {
        if (this.sale) {
            if (this.sale.status === 'ACTIVE') {
                const now = moment();
                const dateStart = moment(this.sale.dateANC);
                const dateEnd = moment(this.sale.dateFinish);
                const total = Math.ceil(dateEnd.endOf('day').diff(dateStart.startOf('days'), 'hours') / 24);
                if (dateStart.isSameOrBefore(now) && dateEnd.isAfter(now)) {
                    const diff = Math.ceil(dateEnd.endOf('day').diff(now.startOf('day'), 'hours') / 24);
                    return `${diff} z ${total}`;
                } else if (dateStart.isAfter(now)) {
                    return total;
                } else if (dateEnd.isBefore(now)) {
                    return 0;
                }
            }
        }
        return '';
    }
    async saveExtUrl() {
        if (!this.sale) return;
        try {
            await saleRepo.update(this.sale.id, { id: this.sale.id, externalUrl: this.newExtUrl });
            this.sale.externalUrl = this.newExtUrl ?? '';
        } catch (e) {}
    }
    isPDF(file: IFile) {
        return file?.mimetype?.includes('pdf');
    }
    hasTag(file: IFile, tag: string) {
        if (!file?.tags?.length) return false;
        const found = file.tags.find((t) => t.code == tag);
        return !!found;
    }
    async toggleTag(file: IFile, tag: string) {
        const hasTag = this.hasTag(file, tag);
        try {
            await fileRepo.toggleTag(file.id, {
                code: tag,
                toggle: !hasTag,
            });
            this.$toasted.success(hasTag ? 'Tag odebrán.' : 'Tag přidán.', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 10000,
            });
            await this.refreshData();
        } catch (e) {
            console.error(e);
        }
    }
    async sendToABRA() {
        try {
            const warnings = await saleRepo.sendToABRA(this.sale?.id);
            if (warnings.length) {
                this.$toasted.error('Při zápisu do ABRA nastal problém. Mohlo dojít k částečnému zápisu.', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
                console.error({ warnings });
            } else {
                this.$toasted.success('Akční ceník zapsán do ABRA', {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                });
            }
            await this.refreshData();
        } catch (e) {
            console.error(e);
        }
    }
    async toggleCountTransfer() {
        try {
            const forbid = !this.sale?.countTransferDisabledAt;
            await saleRepo.forbidCountTransfer(this.sale?.id, forbid);
            this.$toasted.success(
                `Přelévání neobjednaného množství z minulých týdnů ${forbid ? 'zakázáno' : 'povoleno'}.`,
                {
                    fullWidth: true,
                    action: [],
                    fitToScreen: true,
                    closeOnSwipe: true,
                    duration: 10000,
                }
            );
            await this.refreshData();
        } catch (e) {
            console.error(e);
        }
    }
}
/* tslint:disable */
