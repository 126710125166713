
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import CTableHeader from '@/components/table/SilTableHeader.vue';
import CTableFooter from '@/components/table/SilTableFooter.vue';
import { formatDate, getDateRangePickerLocaleData } from '@/utils/dateUtils';
import { IBillOfDelivery, IBillOfDeliveryItem, IOutboundRow } from '@/models/billsofdelivery.models';
import { BAD_CODES } from '@/utils/scm.utils';
import moment from 'moment';
import { BvTableFieldArray } from 'bootstrap-vue';

// Import stylesheet

const billsOfDelivery = repository.get('billsofdelivery');
@Component({
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class BillsOfDelivery extends Vue {
    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    openModal = false;
    filter = '';
    items: IBillOfDelivery[] = [];
    selectRange: { startDate: Date; endDate: Date } = {
        startDate: new Date(),
        endDate: new Date(),
    };
    unfilteredItems: IBillOfDelivery[] = [];
    defaultFilters: any[] = [];
    selectedFilters: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rangePickerLocaleData: any = getDateRangePickerLocaleData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rangePickerRanges: any = {};
    selectableFilters: any[] = [
        {
            title: 'Vyskladněno',
            value: 'delivered',
        },
        {
            title: 'Vyskladněno s rozdílem',
            value: 'delivered_diff',
        },
        {
            title: 'Probíhá expedice',
            value: 'waiting',
        },
        {
            title: 'Potvrzené dodání',
            value: 'confirmed',
        },
    ];
    sortByColumn = 'documentDate';
    sortByDesc = true;
    searchIn: string[] = [
        'displayName',
        'documentDate',
        'importer.name',
        'contactName',
        'createdBy',
        'status',
        'itemCount',
        'palleteCount',
        'serviceLevel',
    ];
    fields: BvTableFieldArray = [
        {
            key: 'importer.name',
            sortable: true,
            label: 'Importér',
        },
        {
            key: 'documentDate',
            sortable: true,
            label: 'Datum dodání',
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
            formatter: (value: string, key: any, item: IBillOfDelivery) => {
                return formatDate(value);
            },
        },
        {
            key: 'displayName',
            sortable: true,
            label: 'Číslo dokladu',
        },
        {
            key: 'contactName',
            sortable: true,
            label: 'Odběratel',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, key: string, item: IBillOfDelivery) => {
                if (!item.contact?.warehouses?.length) return value;
                const warehouse = item.contact.warehouses[0];
                if (!warehouse?.subscriber) return value;
                return `${warehouse.subscriber.name} - ${warehouse.name}`;
            },
        },
        {
            key: 'status',
            sortable: true,
            label: 'Stav',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: string, dd: any, item: IBillOfDelivery) => {
                if (item.outbounds?.length) {
                    const outboundHasFolder = item.outbounds.find((o) => o.folder_id);
                    if (outboundHasFolder) {
                        return 'Potvrzené dodání';
                    }
                }
                if (item.serviceLevel === 0) {
                    return 'Probíhá expedice';
                } else if (item.serviceLevel === 100) {
                    return 'Vyskladněno';
                } else {
                    return `Vyskladněno s rozdílem`;
                }
            },
        },
        {
            key: 'itemCount',
            sortable: true,
            label: 'Počet položek',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: undefined, dd: any, item: any) => {
                return item.items?.length;
            },
        },
        {
            key: 'palleteCount',
            sortable: true,
            label: 'K dodání PAL',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: number, dd: any, item: any) => {
                const palleteCount = item.items.reduce((acc: number, item: any) => acc + item.quantityPalletes, 0);
                return Math.round(palleteCount * 100) / 100;
            },
        },
        {
            key: 'serviceLevel',
            sortable: true,
            label: 'Service level',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value: number) => {
                return `${Math.round(value * 100) / 100} %`;
            },
        },
        {
            key: 'createdBy',
            sortable: true,
            label: 'Uživatel',
        },
        {
            key: 'controls',
            label: '',
        },
    ];
    private setDefaultFilters() {
        this.selectedFilters = Array.from(this.defaultFilters);
        this.filtersChanged(this.selectedFilters);
    }
    private setEmptyFilters() {
        this.selectedFilters = Array.from([]);
        this.filtersChanged(this.selectedFilters);
    }
    private initFilters() {
        const defaultFilters = [];
        defaultFilters.push(this.selectableFilters[0]);
        defaultFilters.push(this.selectableFilters[1]);
        defaultFilters.push(this.selectableFilters[2]);
        this.defaultFilters = defaultFilters;
        this.selectedFilters = Array.from(defaultFilters);
    }
    public async getBillsOfDelivery(): Promise<void> {
        try {
            this.isBusy = true;
            const response: AxiosResponse<IBillOfDelivery[]> = await billsOfDelivery.getAll(
                moment(this.selectRange.startDate),
                moment(this.selectRange.endDate)
            );
            const data: IBillOfDelivery[] = response.data;
            this.totalUnfilteredRows = data.length;

            for (let i = 0; i < data.length; i++) {
                const outboundRows = data[i].outbounds?.map((x) => x.rows)?.flat() ?? [];
                const items = data[i].items ?? [];
                const info = this.fillInItems(items, outboundRows);
                data[i].serviceLevel = info.serviceLevel;
            }

            this.unfilteredItems = data;
            this.filtersChanged(this.selectedFilters);
            // this.items = data.filter((x) =>
            //   this.customFilterFunction(
            //     x,
            //     this.showDelivered,
            //     this.showWaiting,
            //     this.showDeliveredWithDiff,
            //     this.showConfirmed
            //   )
            // );
            // this.totalRows = this.items.length;
        } catch (error) {
            console.error(error);
        } finally {
            this.isBusy = false;
        }
    }

    private getContactName(item: IBillOfDelivery) {
        if (!item.contact?.warehouses?.length) return item.contactName;
        const warehouse = item.contact.warehouses[0];
        if (!warehouse?.subscriber) return item.contactName;
        return `${warehouse.subscriber.name} - ${warehouse.name}`;
    }

    private getSubscriberDetailRoute(item: IBillOfDelivery) {
        if (!item?.subscriber) {
            return;
        }
        return {
            name: 'subscriberdetail',
            params: { id: item.subscriber.id },
        };
    }

    private fillInItems(abraItems: IBillOfDeliveryItem[], tplItems: IOutboundRow[]) {
        const tplItemsClone = JSON.parse(JSON.stringify(tplItems));
        const totalSLHelper: { delivered: number; ordered: number } = { delivered: 0, ordered: 0 };
        for (let i = 0; i < abraItems.length; i++) {
            const _item = abraItems[i];
            const _tplItems = tplItemsClone.filter((x: any) => x.productCode === _item.storecardCode);
            _item.outbounds = _tplItems;
        }
        const abraItemsClone = JSON.parse(JSON.stringify(abraItems));
        for (let i = 0; i < tplItems.length; i++) {
            const _item = tplItems[i];
            const _abraItem = abraItemsClone.find((x: any) => x.storecardCode === _item.productCode);
            _item.origin = _abraItem;
        }
        for (let i = 0; i < abraItems.length; i++) {
            const slHelper = this.getItemServiceLevelHelper(abraItems[i]);
            totalSLHelper.delivered += slHelper.delivered;
            totalSLHelper.ordered += slHelper.ordered;
        }
        const sl = Math.round((totalSLHelper.delivered / totalSLHelper.ordered) * 10000) / 100;
        return {
            serviceLevel: sl,
        };
    }

    private getItemServiceLevelHelper(item: IBillOfDeliveryItem): { delivered: number; ordered: number } {
        const outs = item.outbounds ?? [];
        let ordered = 0;
        let unit = 'KRT';
        if (item.quantityCartons) {
            ordered = item.quantityCartons;
        } else if (item.quantityPalletes) {
            unit = 'PAL';
            ordered = item.quantityPalletes;
        }
        let delivered = 0;
        for (let i = 0; i < outs.length; i++) {
            if (unit === 'KRT') {
                delivered += this.getOutboundAmountKRT(outs[i], item);
            } else if (unit === 'PAL') {
                delivered += this.getOutboundAmountPAL(outs[i], item);
            }
        }
        return {
            ordered,
            delivered,
        };
    }

    private getItemServiceLevel(item: IBillOfDeliveryItem): number {
        const outs = item.outbounds ?? [];
        const ordered = item.quantity ?? 0;
        let delivered = 0;
        for (let i = 0; i < outs.length; i++) {
            delivered += this.getItemAmountInKS(outs[i], item);
        }
        return Math.round((delivered / ordered) * 10000) / 100;
    }

    private getItemAmountInKS(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        switch (item.unitCode) {
            case 'krt':
                ratio = origin?.quantity / origin?.quantityCartons;
                return Math.round(item.amount * ratio * 100) / 100;
            case 'ks':
                return item.amount;
            case 'bal':
                if (BAD_CODES.includes(item.productCode)) {
                    return item.amount * 10;
                }
                return item.amount;
            case 'pal':
                ratio = origin?.quantity / origin?.quantityPalletes;
                return Math.round(item.amount * ratio * 100) / 100;
        }
        return 0;
    }

    private getOutboundAmountKRT(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                val = item.amount;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityCartons;
                val = Math.round((item.amount / ratio) * 100) / 100;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityCartons;
                if (BAD_CODES.includes(item.productCode)) {
                    val = Math.round(((item.amount * 10) / ratio) * 100) / 100;
                } else {
                    val = Math.round((item.amount / ratio) * 100) / 100;
                }
                break;
            case 'pal':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private getOutboundAmountPAL(item: IOutboundRow, origin: IBillOfDeliveryItem) {
        let ratio = 0;
        let val = 0;
        switch (item.unitCode) {
            case 'krt':
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'ks':
                ratio = origin?.quantity / origin?.quantityPalletes;
                val = item.amount / ratio;
                break;
            case 'bal':
                ratio = origin?.quantity / origin?.quantityPalletes;
                if (BAD_CODES.includes(item.productCode)) {
                    val = (item.amount * 10) / ratio;
                } else {
                    val = item.amount / ratio;
                }
                break;
            case 'pal':
                val = item.amount;
                ratio = origin?.quantityCartons / origin?.quantityPalletes;
                val = Math.round(item.amount * ratio * 100) / 100;
                break;
        }
        return val;
    }

    private initRanges() {
        const start = moment().subtract(14, 'days').startOf('day');
        const end = moment().add(1, 'year').endOf('year');
        this.selectRange = {
            startDate: start.toDate(),
            endDate: end.toDate(),
        };
        this.rangePickerRanges = {
            Výchozí: [start.toDate(), end.toDate()],
            'Tento týden': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
            'Minulý týden': [
                moment().subtract(1, 'week').startOf('week').toDate(),
                moment().subtract(1, 'week').endOf('week').toDate(),
            ],
            'Předminulý týden': [
                moment().subtract(2, 'week').startOf('week').toDate(),
                moment().subtract(2, 'week').endOf('week').toDate(),
            ],
            'Tento měsíc': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Minulý měsíc': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Předminulý měsíc': [
                moment().subtract(2, 'month').startOf('month').toDate(),
                moment().subtract(2, 'month').endOf('month').toDate(),
            ],
        };
    }

    public filtered(data: IBillOfDelivery[]) {
        this.totalRows = data.length;
    }
    created() {
        this.initRanges();
        this.initFilters();
        this.getBillsOfDelivery();
    }
    getRouteToDetail(data: IBillOfDelivery) {
        if (!data) return;
        return {
            name: 'billsofdeliverydetail',
            params: {
                id: data.id,
            },
        };
    }
    customFilterFunction(
        item: IBillOfDelivery,
        showDelivered: boolean,
        showWaiting: boolean,
        showDeliveredWithDiff: boolean,
        showConfirmed: boolean
    ) {
        const waiting = item.serviceLevel === 0;
        const delivered = item.serviceLevel === 100;
        const confirmed = item.outbounds?.find((x) => !!x.folder_id);
        if (showConfirmed && confirmed) return true;
        if (!showConfirmed && confirmed) return false;
        if (showDelivered === false && delivered) {
            return false;
        }
        if (showWaiting === false && waiting) {
            return false;
        }
        if (showDeliveredWithDiff === false && !waiting && !delivered) {
            return false;
        }
        return true;
    }
    toggleFilter(filter: { title: string; value: string }) {
        const filterIndex = this.selectedFilters.indexOf(filter);
        if (filterIndex >= 0) {
            this.selectedFilters.splice(filterIndex, 1);
        } else {
            this.selectedFilters.push(filter);
        }
        this.filtersChanged(this.selectedFilters);
    }
    filtersChanged(filters: { title: string; value: string }[]) {
        const showDelivered = !!filters.find((x) => x.value == 'delivered');
        const showWaiting = !!filters.find((x) => x.value == 'waiting');
        const showDeliveredWithDiff = !!filters.find((x) => x.value == 'delivered_diff');
        const showConfirmed = !!filters.find((x) => x.value == 'confirmed');
        this.items = this.unfilteredItems.filter((x) =>
            this.customFilterFunction(x, showDelivered, showWaiting, showDeliveredWithDiff, showConfirmed)
        );
        this.totalRows = this.items.length;
    }
    // private closeModal() {
    //   this.openModal = false;
    //   this.idEditingSupplier = undefined;
    // }

    // private async closeSaveModal() {
    //   this.openModal = false;
    //   this.idEditingSupplier = undefined;
    // }
    private rangeChanged() {
        const now = moment();
        const start = moment(this.selectRange.startDate);
        const end = moment(this.selectRange.endDate);
        if (end.diff(start, 'days') > 31 && now.diff(start, 'days') > 31) {
            this.selectRange.endDate = start.add(31, 'days').toDate();
            this.$toasted.info('Vybráno více než 31 dní, výběr oříznut!', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 4000,
            });
        }
        this.getBillsOfDelivery();
    }
}
