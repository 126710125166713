






















































import { Component, PropSync, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';

import { SilModalsMixin } from '@/mixins/modals';
import { IFile, ITag } from '@/models/gallery.models';

const tagRepo = RepositoryFactory.get('tag');
const fileRepo = RepositoryFactory.get('file');

@Component({
    name: 'file-edit-modal',
})
export default class FileEditModal extends Mixins(SilModalsMixin) {
    private isLoading = false;

    private file?: IFile;
    private availableTags?: ITag[];
    private selectedTags: ITag[] = [];

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;

    @Prop(String) readonly file_id!: string;
    @Prop(Boolean) readonly hideTags!: boolean;

    created() {
        if (!this.file_id) {
            console.error('File Id not supplied!');
            return;
        }
        this.getInitData();
    }
    private closeModal(): void {
        this.$emit('close');
    }
    private async saveFile(): Promise<void> {
        if (!this.file) return;
        try {
            this.isLoading = true;
            const box = {
                id: this.file.id,
                name: this.file.name,
                description: this.file.description,
                tag_ids: this.selectedTags.map((t) => t.id),
            };
            await fileRepo.update(this.file.id, box);
            this.$emit('save');
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }
    private async getInitData(): Promise<void> {
        try {
            this.isLoading = true;
            this.file = await fileRepo.get(this.file_id);
            this.availableTags = await tagRepo.getAll('SYSTEM_FILE');
            this.selectedTags = this.file?.tags || [];
        } catch (error) {
            // ErrorController.showErrorToast(error);
        } finally {
            this.isLoading = false;
        }
    }
}
