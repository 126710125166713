import { Component, Vue } from 'vue-property-decorator';



@Component
export class SilModalsMixin extends Vue {
    public $delete_confirm(text: string, options?: any): Promise<any> {
        return this.$bvModal.msgBoxConfirm(text, {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Odstranit',
            cancelTitle: 'Zrušit',
            footerClass: 'p-2',
            hideHeaderClose: false,
            ...options
        });
    }
    public $confirm(text: string, options?: any): Promise<any> {
        return this.$bvModal.msgBoxConfirm(text, {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'ANO',
            cancelTitle: 'Zrušit',
            footerClass: 'p-2',
            hideHeaderClose: false,
            ...options
        });
    }
}