export function customParseFloat(toParse: number | string | undefined | null): number | null {
    if (typeof toParse === 'undefined' || toParse === null) return null;
    const parsed = Number.parseFloat(toParse.toString().replaceAll(',', '.'));
    if (isNaN(parsed)) {
      return null;
    } else {
      return parsed;
    }
};

export function customParseInt(toParse: number | string | undefined | null): number | null {
    if (typeof toParse === 'undefined' || toParse === null) return null;
    const parsed = Number.parseInt(toParse.toString());
    if (isNaN(parsed)) {
      return null;
    } else {
      return parsed;
    }
};