















































import { Component, Vue, PropSync, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { IFolder } from '@/models/gallery.models';
import ApiService from '@/api/api.services';
import moment from 'moment';
import RoleUtils from '@/utils/role.utils';
import { SilModalsMixin } from '@/mixins/modals';

const fileRepo = RepositoryFactory.get('file');

@Component({
    name: 'gallery-widget',
})
export default class GalleryWidget extends Mixins(SilModalsMixin) {
    isLoading = false;

    base_url = process.env.VUE_APP_API_URL;

    folder: IFolder | null = null;

    hasSCMRole = false;

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;

    @Prop(String) folder_id!: string;
    @Prop(Boolean) showUpload?: boolean;

    created() {
        this.checkRoles();
        if (this.folder_id) {
            this.getFolder();
        }
    }

    async checkRoles() {
        this.hasSCMRole = await RoleUtils.isInRole('USER_SCM');
    }

    async publishCallback(item: any, publish: boolean): Promise<void> {
        const msg = publish ? `Přejete si zveřejnit fotku?` : `Přejete si zrušit zveřejnění fotky?`;
        const allow: boolean = await this.$confirm(msg);
        if (allow) {
            await fileRepo.publish(item.origin.id, publish);
            this.getFolder();
        }
    }

    async getFolder(): Promise<void> {
        try {
            this.isLoading = true;
            this.folder = await fileRepo.getFolder(this.folder_id);
            this.folder?.files?.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    get folderItems() {
        return this.folder?.files
            ?.filter((f) => f.mimetype.includes('image'))
            ?.map((f) => {
                const token = this.getToken();
                const auth = this.getAuthToken();
                return {
                    src: `${this.base_url}/crm/file/${f.id}/download?auth=${auth}&token=${token}`,
                    thumbnail: `${this.base_url}/crm/file/${f.id}/thumbnail?auth=${auth}&token=${token}`,
                    w: 0,
                    h: 0,
                    alt: f.name,
                    title: f.name,
                    published: f.tags?.find((t) => t.code === 'FILE_SUPPLIER'),
                    origin: f,
                };
            });
    }

    getToken() {
        return ApiService.getToken();
    }

    getAuthToken() {
        return ApiService.getAuthToken();
    }

    // UPLOAD
    uploadFiles() {
        document.getElementById('fileUpload')?.click();
    }
    async uploadSelectedFiles(input: any) {
        const formData: FormData = new FormData();
        input.target.files.forEach((file: File) => {
            formData.append(file.name, file);
        });
        try {
            this.isLoading = true;
            await fileRepo.addFiles(this.folder_id, formData);
            this.$toasted.success('Úspěšně nahráno', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 2000,
            });
            this.getFolder();
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }
}
