
import LastFilesCard from '@/components/dashboard/LastFiles.vue';
import NotificationCard from '@/components/dashboard/NotificationCard.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'last-files-card': LastFilesCard,
        'notification-card': NotificationCard,
    },
})
export default class Dashboard extends Vue {
    created() {
        // lol
    }
}
