
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import { IProduct, IProductStockTimeline } from '@/models/product.models';
import { IStoreCard } from '@/models/storecard.models';
import ProductListingTab from '@/components/product/ProductListing.vue';
import ProductBillsTab from '@/components/product/ProductBillsTab.vue';
import ProductSalesTab from '@/components/product/ProductSalesTab.vue';
import ProductPromoTab from '@/components/product/ProductPromoTab.vue';

const products = repository.get('product');
@Component({
    components: {
        'product-bills-tab': ProductBillsTab, // filtrované
        'product-sales-tab': ProductSalesTab, // filtrované nebo nic
        'product-listing-tab': ProductListingTab, // Fakturační cena pryč
        'product-promo-tab': ProductPromoTab,
    },
})
export default class extends Vue {
    private product: IProduct | null = null;
    private stockTimeline: IProductStockTimeline[] | null = null;
    private isLoading = false;
    private tabIndex = 0;
    private tabMap: any = {
        reservations: 6,
    };

    private async getProduct(): Promise<void> {
        try {
            this.isLoading = true;
            const id: string = this.$route.params.id;
            const res: AxiosResponse<IProduct> = await products.get(id);
            const data: IProduct = res.data;
            this.product = data;
        } catch (error) {
        } finally {
            this.isLoading = false;
        }
    }

    private handleQuery(): void {
        const query = this.$route.query;
        if (query?.tab) {
            const idx = this.tabMap[query.tab as string];
            if (idx && idx >= 0) {
                this.tabIndex = idx;
            }
        }
    }

    private goBack() {
        this.$router.back();
    }

    private routeToStoreCard(id: number | undefined) {
        if (!id) return;
    }

    get primaryStoreCard(): IStoreCard | undefined {
        const sc = this.product?.storecards?.find((sc) => sc.link?.primary ?? false);
        return sc;
    }

    get secondaryStoreCards(): IStoreCard[] | undefined {
        return this.product?.storecards?.filter((sc) => !(sc.link?.primary ?? false));
    }

    async created() {
        this.initialize();
    }

    private async initialize() {
        await Promise.all([this.getProduct()]);
        this.handleQuery();
    }

    private chartStyles = {
        height: '300px',
        width: '100%',
        margin: 'auto',
    };

    private get getEANSummary() {
        const primaryStoreCard = this.product?.storecards?.find((sc) => sc.link?.primary === true);
        const cartonStoreCardUnit = primaryStoreCard?.storecardunits?.find((scu) => scu.code === 'krt');
        return `${primaryStoreCard?.ean} (${cartonStoreCardUnit?.ean})`;
    }
}
