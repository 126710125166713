
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import CTableHeader from '@/components/table/SilTableHeader.vue';
import CTableFooter from '@/components/table/SilTableFooter.vue';
import { formatDate, formatDateTime, getDateRangePickerLocaleData } from '@/utils/dateUtils';
import { IReceivedOrder } from '@/models/receivedorder.models';
import moment from 'moment';

const receivedOrder = repository.get('receivedorder');
@Component({
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class ReceivedOrders extends Vue {
    //
    selectRange: { startDate: Date; endDate: Date } = {
        startDate: new Date(),
        endDate: new Date(),
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rangePickerLocaleData: any = getDateRangePickerLocaleData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rangePickerRanges: any = {};
    //
    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    openModal = false;
    filter = '';
    items: IReceivedOrder[] = [];
    sortByColumn = 'createdDate';
    sortByDesc = true;
    fields: any[] = [
        {
            key: 'importer.name',
            sortable: true,
            label: 'Importér',
        },
        {
            key: 'documentDate',
            sortable: true,
            label: 'Datum přijetí',
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
            formatter: (value: string, key: any, item: IReceivedOrder) => {
                return formatDate(value);
            },
        },

        {
            key: 'deliveryDate',
            sortable: true,
            label: 'Datum dodání',
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'orderDisplayName',
            sortable: true,
            label: 'Číslo dokladu',
        },
        {
            key: 'contactName',
            sortable: true,
            label: 'Odběratel',
            formatter: (value: string, key: string, item: IReceivedOrder) => {
                if (!item.contact?.warehouses?.length) return value;
                const warehouse = item.contact.warehouses[0];
                if (!warehouse?.subscriber) return value;
                return `${warehouse.subscriber.name} - ${warehouse.name}`;
            },
        },
        {
            key: 'itemCount',
            sortable: true,
            label: 'Počet položek',
        },
        {
            key: 'palleteCount',
            sortable: true,
            label: 'Počet palet',
            formatter: (value: number) => {
                const v = value ?? 0;
                return Math.round(v * 100) / 100;
            },
        },
        {
            key: 'createdDate',
            sortable: true,
            label: 'Vytvořeno',
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
            formatter: (value: string, key: any, item: IReceivedOrder) => {
                return formatDateTime(value);
            },
        },
        {
            key: 'createdBy',
            sortable: true,
            label: 'Vytvořil',
        },
        {
            key: 'controls',
            label: '',
        },
    ];
    public async getReceivedOrders(): Promise<void> {
        try {
            this.isBusy = true;
            const response: AxiosResponse<IReceivedOrder[]> = await receivedOrder.getAll(
                moment(this.selectRange.startDate),
                moment(this.selectRange.endDate)
            );
            const data: IReceivedOrder[] = response.data;
            this.totalRows = data.length;
            this.totalUnfilteredRows = data.length;
            this.items = data;
        } catch (error) {
            console.error(error);
        } finally {
            this.isBusy = false;
        }
    }
    public filtered(data: IReceivedOrder[]) {
        this.totalRows = data.length;
    }
    created() {
        this.initRanges();
        this.getReceivedOrders();
    }
    getRouteToDetail(data: IReceivedOrder) {
        if (!data) return;
        return {
            name: 'receivedorderdetail',
            params: {
                id: data.id,
            },
        };
    }
    getRouteToBillOfDelivery(data: IReceivedOrder) {
        if (!data) return;
        return {
            name: 'billsofdeliverydetail',
            params: {
                id: data.idAbra,
                bod: true,
            },
        };
    }
    formatDate(data: string) {
        return formatDate(data);
    }
    // private closeModal() {
    //   this.openModal = false;
    //   this.idEditingSupplier = undefined;
    // }

    // private async closeSaveModal() {
    //   this.openModal = false;
    //   this.idEditingSupplier = undefined;
    // }

    private initRanges() {
        const start = moment().subtract(1, 'month').startOf('day');
        const end = moment().add(10, 'year').endOf('year');
        this.selectRange = {
            startDate: start.toDate(),
            endDate: end.toDate(),
        };
        this.rangePickerRanges = {
            Výchozí: [start.toDate(), end.toDate()],
            'Tento týden': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
            'Minulý týden': [
                moment().subtract(1, 'week').startOf('week').toDate(),
                moment().subtract(1, 'week').endOf('week').toDate(),
            ],
            'Předminulý týden': [
                moment().subtract(2, 'week').startOf('week').toDate(),
                moment().subtract(2, 'week').endOf('week').toDate(),
            ],
            'Tento měsíc': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Minulý měsíc': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Předminulý měsíc': [
                moment().subtract(2, 'month').startOf('month').toDate(),
                moment().subtract(2, 'month').endOf('month').toDate(),
            ],
        };
    }
    private rangeChanged() {
        const now = moment();
        const start = moment(this.selectRange.startDate);
        const end = moment(this.selectRange.endDate);
        if (end.diff(start, 'days') > 365 && now.diff(start, 'days') > 365) {
            this.selectRange.endDate = start.add(365, 'days').toDate();
            this.$toasted.info('Vybráno více než 365 dní, výběr oříznut!', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 4000,
            });
        }
        this.getReceivedOrders();
    }
}
