
import ApiService from '@/api/api.services';
import RepositoryFactory from '@/api/RepositoryFactory';
import { IFile } from '@/models/gallery.models';
import { Component, Vue } from 'vue-property-decorator';
import SaleDetailModal from '../sale/SaleDetailModal.vue';

const saleRepo = RepositoryFactory.get('sale');

@Component({
    name: 'last-files-card',
    components: {
        'sale-detail-modal': SaleDetailModal,
    },
})
export default class LastFilesCard extends Vue {
    isLoading = false;

    private base_url = process.env.VUE_APP_API_URL;
    private files: IFile[] = [];

    //modal
    private saleDetailID: number | null = null;
    private openDetailModal = false;

    created() {
        this.getLastFiles();
    }
    async getLastFiles() {
        try {
            this.files = await saleRepo.getLastFiles();
        } catch (e) {
            console.log(e);
        }
    }
    get galleryFiles() {
        return this.files?.map((f) => {
            const token = this.getToken();
            const auth = this.getAuthToken();
            return {
                src: `${this.base_url}/crm/file/${f.id}/download?auth=${auth}&token=${token}`,
                thumbnail: `${this.base_url}/crm/file/${f.id}/thumbnail?auth=${auth}&token=${token}`,
                w: 0,
                h: 0,
                alt: f.name,
                title: f.name,
                published: f.tags?.find((t) => t.code === 'FILE_SUPPLIER'),
                origin: f,
                sale: f.folder?.sale,
            };
        });
    }
    private getToken() {
        return ApiService.getToken();
    }

    private getAuthToken() {
        return ApiService.getAuthToken();
    }

    private closeDetailModal() {
        this.openDetailModal = false;
        this.saleDetailID = null;
    }

    private saleRedirectCallback(sale: any) {
        this.saleDetailID = sale.id;
        this.openDetailModal = true;
    }
}
