































import { Component, Prop, Mixins } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import CTableHeader from '../table/SilTableHeader.vue';
import CTableFooter from '../table/SilTableFooter.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import { SilModalsMixin } from '@/mixins/modals';
import { ISaleSummaryHelper } from '@/models/sale.models';
import MathUtils from '@/utils/math.utils';

const saleRepo = RepositoryFactory.get('sale');

@Component({
    name: 'sale-summary-tab',
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
    },
})
export default class SaleSummaryTab extends Mixins(SilModalsMixin) {
    @Prop(Number) readonly sale_id!: number;

    items: ISaleSummaryHelper[] = [];

    // TABLE
    isLoading = false;
    perPageCount = 20;
    totalRowsCount = 0;
    totalUnfilteredRowsCount = 0;
    currentPage = 1;
    filter = '';
    fields: BvTableFieldArray = [
        {
            key: 'code',
            label: 'Kód',
            sortable: true,
        },
        {
            key: 'name',
            label: 'Název',
            sortable: true,
        },
        {
            key: 'forecast',
            label: 'Celkový FRC (KS)',
            sortable: true,
        },
        {
            key: 'ordered',
            label: 'Objednáno (KS)',
            sortable: true,
        },
        {
            key: 'delivered',
            label: 'Dodáno (KS)',
            sortable: true,
        },
        {
            key: 'service_level',
            label: 'SL (%)',
            sortable: true,
            formatter: (value) => {
                return `${value}%`;
            },
        },
        {
            key: 'performance',
            label: 'Plnění (%)',
            sortable: true,
            formatter: (value) => {
                return `${value}%`;
            },
        },
        {
            key: 'remains',
            label: 'Zbývá (KS)',
            sortable: true,
        },
    ];

    created() {
        this.getSaleSummary();
    }

    private async getSaleSummary() {
        try {
            this.isLoading = true;
            const data: ISaleSummaryHelper[] = await saleRepo.getSaleSummary(this.sale_id);
            this.totalRowsCount = data.length;
            this.totalUnfilteredRowsCount = data.length;
            this.items = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }
}
