import UserRepository from '@/api/user/user.repository';
import SaleRepository from './sale/sale.repository';
import GlobalRepository from './global/global.repository';
import SubscriberRepository from './subscriber/subscriber.repository';
import FileRepository from './gallery/file.repository';
import StockRepository from './stock/stock.repository';
import ProductRepository from './product/product.repository';
import BillsOfDeliveryRepository from './billsofdelivery/billsofdelivery.repository';
import ReceivedOrderRepository from './receivedorder/receivedorder.repository';
import NotificationRepository from './notification/notification.repository';
import TagRepository from './tag/tag.repository';
import WareHouseRepository from './warehouse/warehouse.repository';
import SupplierRepository from './supplier/supplier.repository';

class Repositories {
    static repositories: any = {
        user: UserRepository,
        sale: SaleRepository,
        global: GlobalRepository,
        subscriber: SubscriberRepository,
        file: FileRepository,
        stock: StockRepository,
        product: ProductRepository,
        billsofdelivery: BillsOfDeliveryRepository,
        receivedorder: ReceivedOrderRepository,
        notification: NotificationRepository,
        tag: TagRepository,
        warehouse: WareHouseRepository,
        supplier: SupplierRepository,
    };
}

export default class RepositoryFactory {
    public static get = (name: string) => {
        return Repositories.repositories[name];
    };
}

