
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { AxiosResponse } from 'axios';
import CTableHeader from '@/components/table/SilTableHeader.vue';
import CTableFooter from '@/components/table/SilTableFooter.vue';
import { IOrder } from '@/models/order.models';
import NewSaleModal, { SaleState } from '@/components/sale/NewSale.vue';
import { formatDateTime, formatDate, formatDateWithWeek } from '@/utils/dateUtils';
import { IProduct } from '@/models/product.models';
import { ISale } from '@/models/sale.models';
import moment from 'moment';
import { BvTableFieldArray } from 'node_modules/bootstrap-vue/src';
import SaleDetailModal from '@/components/sale/SaleDetailModal.vue';
import GalleryModal from '@/components/gallery/GalleryModal.vue';
import RoleUtils from '@/utils/role.utils';
// Import stylesheet

const saleRepo = repository.get('sale');

@Component({
    components: {
        'sil-table-header': CTableHeader,
        'sil-table-footer': CTableFooter,
        'new-sale-modal': NewSaleModal,
        'sale-detail-modal': SaleDetailModal,
        'gallery-modal': GalleryModal,
    },
})
export default class Sales extends Vue {
    totalUnfilteredRows = 0;
    totalRows = 0;
    isBusy = false;
    currentPage = 1;
    perPage = 50;
    openModal = false;
    openDetailModal = false;
    saleDetailID = null;
    filter = '';
    items: ISale[] = [];
    unfilteredItems: ISale[] = [];
    isDownloading = false;
    sortByColumn = 'dateStart';
    sortByDesc = false;
    showPlannedSales = false;
    hasSCMRole = false;
    SCMBaseURL = '';
    fields: BvTableFieldArray = [
        {
            key: 'saleNumber',
            sortable: true,
            label: 'Číslo',
        },
        {
            key: 'name',
            sortable: true,
            label: 'Název akce',
        },
        {
            key: 'subscriberName',
            sortable: true,
            label: 'Odběratel',
            sortByFormatted: (value: any, key: any, item: any) => {
                return item.subscriber?.name ?? '';
            },
        },
        {
            key: 'gallery',
            sortable: false,
            label: 'Galerie',
        },
        {
            key: 'dateStart',
            sortable: true,
            label: 'Od',
            filterByFormatted: true,
            formatter: (value: any, key: any, item: IOrder) => {
                return value ? formatDate(value) : '';
            },
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'dateFinish',
            sortable: true,
            label: 'Do',
            filterByFormatted: true,
            formatter: (value: any, key: any, item: IOrder) => {
                return value ? formatDate(value) : '';
            },
            sortByFormatted: (value: string) => {
                return new Date(value).getTime();
            },
        },
        {
            key: 'daysRemaining',
            sortable: true,
            label: 'Zbývá',
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (value: any, key: any, item: ISale) => {
                const now = moment().startOf('day');
                const finish = moment(item.dateFinish).endOf('day');
                const diff = Math.ceil(finish.diff(now, 'hours') / 24);
                return Math.max(diff, 0);
            },
        },
        {
            key: 'status',
            sortable: true,
            label: 'Stav',
            formatter: (value: string, key: any, item: ISale) => {
                if (value === 'ACTIVE') {
                    const now = new Date();
                    const dateStart = new Date(item.dateStart);
                    if (dateStart > now) {
                        return Object(SaleState)[item.status];
                    } else {
                        const dateEnd = new Date(item.dateFinish);
                        if (dateEnd >= now) {
                            return 'Aktivní';
                        } else {
                            return 'Ukončená';
                        }
                    }
                }
                return Object(SaleState)[value];
            },
        },
    ];
    showDetailModal(row: any) {
        if (!row) return;
        this.saleDetailID = row.id;
        this.openDetailModal = true;
    }
    getRouteToSubscriber(item: ISale) {
        return {
            name: 'subscriberdetail',
            params: {
                id: item.subscriber_id?.toString() ?? '#',
                subscriberName: item.subscriber?.name ?? '#',
                from: this.$route.name ?? '',
            },
        };
    }
    closeModal() {
        this.openModal = false;
        this.getSales();
    }
    closeDetailModal() {
        this.openDetailModal = false;
        this.saleDetailID = null;
    }
    public async getSales(): Promise<void> {
        try {
            this.isBusy = true;
            const response: ISale[] = await saleRepo.getAll();
            const data: ISale[] = response;
            this.totalUnfilteredRows = data.length;
            this.unfilteredItems = data;
            this.items = data.filter((x) => this.customFilterFunction(x, this.showPlannedSales));
            this.totalRows = this.items.length;
        } catch (error) {
            //401, 500 - interceptory
        } finally {
            this.isBusy = false;
        }
    }
    public filtered(data: IProduct[]) {
        this.totalRows = data.length;
    }
    created() {
        this.SCMBaseURL = process.env.VUE_APP_SCM_URL;
        this.checkRoles();
        this.getSales();
    }
    async checkRoles() {
        this.hasSCMRole = await RoleUtils.isInRole('USER_SCM');
    }
    customFilterFunction(item: ISale, showPlanned: boolean) {
        const planned = new Date(item.dateStart) > new Date() || item.status === 'CONCEPT';
        if (showPlanned === false && planned) {
            return false;
        }
        return true;
    }
    showPlannedSalesChanged(val: boolean) {
        this.items = this.unfilteredItems.filter((x) => this.customFilterFunction(x, val));
        this.totalRows = this.items.length;
    }
    isConcept(data: any) {
        return data.status === 'CONCEPT';
    }
    getRouteToDetail(data: any) {
        if (!data) return;
        return {
            name: 'saledetail',
            params: { id: data.id, saleName: data.name },
        };
    }
}
