import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';
import { IGlobal } from '@/models/global.models';
import { IFile, IFolder } from '@/models/gallery.models';

const resource = 'crm/file';

export default class FileRepository {
    public static download = async (id: string): Promise<ArrayBuffer> => {
        try {
            const response: AxiosResponse<ArrayBuffer> = await Repository.get(`${resource}/${id}/download`, {
                responseType: 'arraybuffer',
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static get = async (id: string): Promise<IFile> => {
        try {
            const response: AxiosResponse<IFile> = await Repository.get(`${resource}/${id}`);
            return response.data;
        } catch (error) {
            throw Promise.reject(error);
        }
    };
    public static getFolder = async (id: string): Promise<IFolder> => {
        try {
            const response: AxiosResponse<IFolder> = await Repository.get(`${resource}/folder/${id}`);
            return response.data;
        } catch (error) {
            throw Promise.reject(error);
        }
    };
    public static update = async (id: string, data: IFile): Promise<IFile> => {
        try {
            const response: AxiosResponse<IFile> = await Repository.put(`${resource}/${id}`, data);
            return response.data;
        } catch (error) {
            throw Promise.reject(error);
        }
    };
    public static delete = async (id: string): Promise<IFile> => {
        try {
            const response: AxiosResponse<IFile> = await Repository.delete(`${resource}/${id}`);
            return response.data;
        } catch (error) {
            throw Promise.reject(error);
        }
    };
    public static addFiles = async (id: number, files: FormData): Promise<IFile[]> => {
        try {
            const response = await Repository.post(`${resource}/folder/${id}/file`, files);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static publish = async (id: number, publish: boolean): Promise<void> => {
        try {
            const response = await Repository.post(`${resource}/${id}/publish`, { publish });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
