import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';
import { IReceivedOrder } from '@/models/receivedorder.models';

const resource = 'crm/receivedorder';

export default class ReceivedOrderRepository {
    public static getAll = async (
        start: moment.Moment,
        end: moment.Moment
    ): Promise<AxiosResponse<IReceivedOrder[]>> => {
        try {
            return Repository.get(`${resource}`, {
                params: {
                    start: start.format('YYYY-MM-DD'),
                    end: end.format('YYYY-MM-DD'),
                },
            });
        } catch (error) {
            throw error;
        }
    };
    public static get = async (id: string): Promise<AxiosResponse<IReceivedOrder>> => {
        try {
            return Repository.get(`${resource}/${id}`);
        } catch (error) {
            throw error;
        }
    };
    public static getByAbraId = async (
        importer_id: number,
        abra_id: string
    ): Promise<AxiosResponse<IReceivedOrder>> => {
        try {
            return Repository.get(`${resource}/byabra`, {
                params: { importer_id, abra_id },
            });
        } catch (error) {
            throw error;
        }
    };
    public static getBillOfDeliveryId = async (idAbra: string): Promise<AxiosResponse<{ id: number }>> => {
        try {
            return Repository.get(`${resource}/billofdelivery?id=${idAbra}`);
        } catch (error) {
            throw error;
        }
    };
}
